"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Tips {
  /**
   * Fetches a collection of stock tips for a given listing
   * @param {String} listingId
   * @param {Boolean} status
   * @returns {Promise}
   */
  static getTipsByListing(listingId) {
    var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return (0, _API.GET)("tips/?filter[where][listingId]=".concat(listingId, "&filter[where][status]=").concat(status, "&filter[order]=startTimestamp DESC"), true);
  }

}

var _default = Tips;
exports.default = _default;