"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Reactions {
  static getMyList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("reactions/mine?filter=".concat(JSON.stringify(combinedFilters)));
  }

  static deleteReaction(itemId) {
    return (0, _API.DELETE)("reactions", itemId);
  }

}

_defineProperty(Reactions, "postReaction", (target, reaction) => {
  return (0, _API.POST)("reactions", {
    target,
    reaction
  });
});

_defineProperty(Reactions, "getForItem", target => {
  return (0, _API.GET)("reactions/list/?targets=".concat(target)).then(result => {
    if (result.hasOwnProperty("reactionSummaries") && result.reactionSummaries.length > 0) {
      return result.reactionSummaries[0];
    }

    return {
      target,
      reactions: {}
    };
  });
});

_defineProperty(Reactions, "getMyReaction", reactionId => {
  return (0, _API.GET)("reactions/mine/".concat(reactionId));
});

_defineProperty(Reactions, "getMyReactionToItem", target => {
  return (0, _API.GET)("reactions/mine?filter={\"where\":{\"target\":\"".concat(target, "\"}}"));
});

_defineProperty(Reactions, "getForItemsByArray", function () {
  var targets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return (0, _API.GET)("reactions/list/?targets=".concat(targets.join()));
});

var _default = Reactions;
exports.default = _default;