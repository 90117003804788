"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class ChartsAPI {
  /**
  * Makes calls to charts API with mutex
  * @return {Promise}
  */
  mutexd() {
    return new Promise();
  }
  /**
   * Gets newest chart data in DESC order
   * @param {string} listingId
   * @param {number} limit - number of datapoints per fetch
   * @return {Promise} - resolves to an array
   */


  static getByListing(listingId) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 15;
    var timeScale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1440;
    return (0, _API.GET)("charts" + "?filter[where][listingId]=".concat(listingId) + "&filter[where][timeScale]=".concat(timeScale) + "&filter[order]=timestamp+DESC" + "&filter[limit]=".concat(limit)).then(ChartsAPI.prepareData).catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
   * Returns chart data older than given timestamp
   * @param {string} listingId
   * @param {number} timestamp - timestamp in numerical format
   * @param {number} limit - number of datapoints per fetch
   * @return {Promise} - resolves to an array
   */


  static getByListingOT(listingId, timestamp) {
    var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 15;
    var timeScale = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1440;
    return (0, _API.GET)("charts" + "?filter[where][listingId]=".concat(listingId) + "&filter[where][timeScale]=".concat(timeScale) + "&filter[order]=timestamp+DESC" + "&filter[limit]=".concat(limit) + "&filter[where][timestamp][lt]=".concat(timestamp)).then(ChartsAPI.prepareData).catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
  * Returns chart data that is between start and end timstamp
  * @param {string} listingId
  * @param {number} startTimestamp
  * @param {number} endTimestamp
  * @return {Promise} - resolves to an array
  */


  static getByListingTimeWindow(listingId, startTimestamp, endTimestamp) {
    var timeScale = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1440;
    return (0, _API.GET)("charts" + "?filter[where][listingId]=".concat(listingId) + "&filter[where][timeScale]=".concat(timeScale) + "&filter[order]=timestamp+DESC" + "&filter[where][and][1][timestamp][gte]=".concat(startTimestamp) + "&filter[where][and][0][timestamp][lte]=".concat(endTimestamp)).then(ChartsAPI.prepareData).catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
   * Prepares data received from the API.
   * Data comes in descending order, meaning newer trades first.
   * This function rotates it and converts types where necessary
   * @param {Object[]} data - array of objects containing properties as in "for" loop
   * @return {Object[]}
   */


  static prepareData(data) {
    if (!data || !data.length) return []; //sanity check

    var preparedData = new Array(data.length); //READ: END > FRONT
    //WRITE: FRONT > END

    for (var write = 0, read = data.length - 1; read >= 0; read--, write++) {
      var each = data[read];
      preparedData[write] = {
        timestamp: each.timestamp,
        date: new Date(each.timestamp * 1000),
        open: Number(each.open["$numberDecimal"]),
        close: Number(each.close["$numberDecimal"]),
        low: Number(each.low["$numberDecimal"]),
        high: Number(each.high["$numberDecimal"]),
        volume: each.volume,
        timeScale: each.timeScale
      };
    }

    return preparedData;
  }

}

var _default = ChartsAPI;
exports.default = _default;