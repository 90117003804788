"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Issues {
  /**
   * Fetches single issue
   * @param {string} issueId
   * @return {Promise}
   */
  static get(issueId) {
    return _API.LOOPBACK.getOneByField("issues", "id", issueId);
  }
  /**
   * @param {number} filter - loopback-filters
   */


  static getList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("issues?filter=".concat(JSON.stringify(combinedFilters)));
  }
  /**
   * Fetches single issue using company id
   * @param {string} companyId
   * @return {Promise}
   */


  static getByCompanyId(companyId) {
    var includeListings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (includeListings) {
      return _API.LOOPBACK.query("issues", "filter[where][issueCompanyId]=".concat(companyId, "&filter[include]=listings"));
    }

    return _API.LOOPBACK.getManyByField("issues", "issueCompanyId", companyId);
  }
  /**
   * Fetches single issue using ISIN
   * @param {string} isin
   * @return {Promise}
   */


  static getByISIN(isin) {
    return _API.LOOPBACK.getOneByField("issues", "isin", isin);
  }

  static getByIds() {
    var ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var include = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return (0, _API.GET)("issues?filter=".concat(JSON.stringify({
      where: {
        id: {
          inq: ids
        }
      },
      include
    }))).then(data => {
      var byId = {};

      if (Array.isArray(data)) {
        data.forEach(elem => {
          byId[elem.id] = Issues.mapLoopbackFields(elem);
        });
      }

      return byId;
    }).catch(e => {
      console.error(e);
      return {};
    });
  }
  /**
   * Returns new object with fields named without prefix *listing*
   */


  static mapLoopbackFields(issue) {
    return _objectSpread({
      isSuspended: issue.issueIsSuspended,
      companyId: issue.issueCompanyId,
      currencyCode: issue.issueCurrencyCode,
      shares: issue.issueShares,
      name: issue.issueName,
      factSetSymId: issue.issueFactSetSymId,
      securityType: issue.issueSecurityType,
      fundamentalsId: issue.issueFundamentalsId,
      sharesLegacy: issue.issueSharesLegacy
    }, issue);
  }

}

var _default = Issues;
exports.default = _default;