"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Packages {
  /**
   * Fetches single company
   * @return {Promise}
   */
  static get() {
    return _API.LOOPBACK.getManyByField("packages", "packageIsVisible", "true");
  }

  static getOne(id) {
    return (0, _API.GET)("packages/".concat(id));
  }

}

var _default = Packages;
exports.default = _default;