"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

// https://api.voxmarkets.co.uk/v2/teams?filter%5Bwhere%5D%5BcompanyId%5D=5aaa3f365e22c30011e8f045
class Teams {
  static get(id) {
    return (0, _API.GET)("teams/".concat(id));
  }
  /**
   * @param {number} filter - loopback-filters
   */


  static getList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "teamDisplayOrder ASC"
    }, filter);
    return (0, _API.GET)("teams?filter=".concat(JSON.stringify(combinedFilters)));
  }

}

var _default = Teams;
exports.default = _default;