"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Content {
  /**
   * Fetches a page by its unique identifier
   * @param {String} pageId
   * @returns {Promise}
   */
  static getContentPage(pageId) {
    return (0, _API.GET)("cms/pages/".concat(pageId));
  }
  /**
   *
   * @param {String} leafname
   * @returns {Promise}
   */


  static getArticleCollectionByLeafname(leafname) {
    return (0, _API.GET)("cms/collections/?filter={\"where\":{\"collectionLeafname\":\"".concat(leafname, "\"}}"), false);
  }
  /**
   *
   * @param {String} collectionId
   * @param {number} filter - loopback-filters
   * @returns {Promise}
   */


  static getCollectionArticlesList(collectionId, filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "publishedAt DESC"
    }, filter);
    return (0, _API.GET)("cms/collections/".concat(collectionId, "/articles?filter=").concat(JSON.stringify(combinedFilters)));
  }
  /**
   *
   * @param {String} collectionId
   * @param {Boolean} withArticles
   * @returns {Promise}
   */


  static getArticleCollection(collectionId) {
    var withArticles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return (0, _API.GET)("cms/collections/".concat(collectionId, "/").concat(withArticles ? "?filter[include]=articles" : ""));
  }
  /**
   * Retrieves a list of articles by a given author, latest first
   * @param {String} authorId
   * @param {Number} limit
   * @returns {Promise}
   */


  static getArticlesByAuthor(authorId) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
    return (0, _API.GET)("cms/articles/?filter[limit]=".concat(limit, "&filter[where][articleAuthorId]=").concat(authorId, "&filter[order]=publishedAt DESC"));
  }
  /**
   * Retrieves a list of articles in a given category, latest first
   * @param {String} category
   * @param {Number} limit
   * @returns {Promise}
   */


  static getArticlesByCategory(category) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
    return (0, _API.GET)("cms/articles/?filter[limit]=".concat(limit, "&filter[where][articleCategory]=").concat(category, "&filter[order]=publishedAt DESC"));
  }
  /**
   * Retrieves a set of articles by storyId
   * @param {Array} articleIds
   * @returns {Promise}
   */


  static getArticles(articleIds) {
    return (0, _API.GET)("cms/articles/?filter={\"where\":{\"id\":{\"inq\":".concat(JSON.stringify(articleIds), "}}, \"order\": \"publishedAt DESC\"}"));
  }
  /**
   * Looks for an article using the provided leaf name
   * @param leafname
   */


  static getArticleByLeafname(leafname) {
    return (0, _API.GET)("cms/articles/?filter={\"where\":{\"articleLeafname\":\"".concat(leafname, "\"}}"), false);
  }
  /**
   * Retrieves a list of articles by filter
   * @param {number} filter - loopback-filters
   */


  static getArticlesList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "publishedAt DESC"
    }, filter);
    return (0, _API.GET)("cms/articles/?filter=".concat(JSON.stringify(combinedFilters)));
  }

  static getArticlesListInCollection() {
    var collectionId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "5afac5677708da0011824773";
    var filter = arguments.length > 1 ? arguments[1] : undefined;
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "publishedAt DESC"
    }, filter);
    return (0, _API.GET)("cms/collections/".concat(collectionId, "/articles/?filter=").concat(JSON.stringify(combinedFilters)));
  }
  /**
   * Looks for a series using the provided leaf name
   * @param leafname
   */


  static getSeriesByLeafname(leafname) {
    return (0, _API.GET)("cms/series/?filter={\"where\":{\"seriesLeafname\":\"".concat(leafname, "\"}}"), false);
  }
  /**
   * Retrieves a list of series by filter
   * @param {number} filter - loopback-filters
   */


  static getSeriesList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "createdAt DESC"
    }, filter);
    return (0, _API.GET)("cms/series/?filter=".concat(JSON.stringify(combinedFilters)));
  }

}

var _default = Content;
exports.default = _default;