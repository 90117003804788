"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

var _Subscription = _interopRequireDefault(require("../types/Subscription.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Subscriptions {
  /**
   * Fetches all subscriptions for user
   * @return {Promise<[Subscription]>}
   */
  static get() {
    return _API.LOOPBACK.getMany(Subscriptions.SERVICE_NAME, {}).then(data => {
      return data.map(item => new _Subscription.default(item.packageId, item.metadata, item.isSubscribed, item.id));
    });
  }

  static getOne(subscriptionId) {
    return (0, _API.GET)("".concat(Subscriptions.SERVICE_NAME, "?filter[where][packageId]=").concat(subscriptionId));
  }

  static add(data) {
    return (0, _API.POST)(Subscriptions.SERVICE_NAME, data);
  }

  static update(subscriptionId, field, value) {
    return (0, _API.UPDATE)(Subscriptions.SERVICE_NAME, subscriptionId, field, value);
  }

  static addEmail(packageId, email, metadata) {
    return (0, _API.POSTQueryStringNoJwt)("emailsubscriptions", "packageId=".concat(packageId, "&metadata=").concat(metadata, "&email=").concat(email));
  }

  static confirmSubscription(subscriptionId) {
    return (0, _API.NoJwtPOST)("actionQueue", {
      subscriptionId: subscriptionId,
      action: "confirm"
    });
  }

  static confirmUnsubscribe(subscriptionId) {
    return (0, _API.NoJwtPOST)("actionQueue", {
      subscriptionId: subscriptionId,
      action: "unsubscribe"
    });
  }

  static track(action, payload) {
    return (0, _API.NoJwtPOST)("actionQueue", {
      payload: payload,
      action: action
    });
  }

}

_defineProperty(Subscriptions, "SERVICE_NAME", "subscriptions");

var _default = Subscriptions;
exports.default = _default;