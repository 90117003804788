"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

/*
* valid fields: [
*   "id",
*   "exchangeName",
*   "exchangeShortCode",
*   "exchangeMarketCode",
*   "exchangeFactSetCode",
*   "exchangeCountryId",
*   "createdAt",
*   "createdBy",
*   "updatedAt",
*   "updatedBy",
*   "owner",
* ]
*/
class Exchanges {
  /**
  * Fetches exchange
  * @param {string} exchangeId
  * @return {Promise}
  */
  static get(exchangeId) {
    return _API.LOOPBACK.getOneByField("exchanges", "exchanges", exchangeId);
  }
  /**
  * Fetches single exchange by its short name
  * @param {string} shortName
  * @return {Promise}
  */


  static getByShortName(shortName) {
    return _API.LOOPBACK.getOneByField("exchanges", "exchangeShortCode", shortName);
  }
  /**
  * Fetches single exchange by its short name
  * @param {string} shortName
  * @return {Promise}
  */


  static getByName(name) {
    return _API.LOOPBACK.getOneByField("exchanges", "exchangeName", name);
  }
  /**
  * Fetches single exchange based on it's FactSet code
  * @param {string} code
  * @return {Promise}
  */


  static getByFactSetCode(code) {
    return _API.LOOPBACK.getOneByField("exchanges", "exchangeFactSetCode", code);
  }
  /**
  * Fetches single exchange based on it's owner
  * @param {string} owner
  * @return {Promise}
  */


  static getByOwner(owner) {
    return _API.LOOPBACK.getOneByField("exchanges", "owner", owner);
  }

}

var _default = Exchanges;
exports.default = _default;