"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _config = _interopRequireDefault(require("../config"));

var _Authentication = _interopRequireDefault(require("../Authentication"));

var _Errors = require("../Errors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class Fetcher {
  /**
   * Encode the body to be sent with Restful api
   * @param {Object} obj : body object to be sent
   * @return {Object}
   */
  static restfulEncodeBody(obj) {
    if (!obj || typeof obj != "object") return "";
    return Object.keys(obj).map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]);
    }).join("&");
  }
  /**
   * converts the body object into url query string params
   */


  static queryParams(obj) {
    return Object.keys(obj).map(key => "".concat(key, "=").concat(obj[key])).join("&");
  }
  /**
   * Makes request to wp-admin/admin-ajax endpoint
   * @param {Object} body
   * @return {Promise}
   */


  static ADMIN_AJAX(body) {
    body = body instanceof FormData ? body : this.restfulEncodeBody(body);
    return fetch("".concat(_config.default.get("PUBLIC_HOST"), "/wp-admin/admin-ajax.php"), {
      method: "POST",
      credentials: "include",
      body: body
    });
  }
  /**
   * Sends a request to /restful
   * @param {Object} body parameters to be sent .
   */


  static RESTFUL(body) {
    var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "POST";

    if (method === "GET") {
      return fetch("".concat(_config.default.get("PUBLIC_HOST"), "/restful?").concat(this.queryParams(body)), {
        credentials: "include",
        headers: _Authentication.default.injectCookie({
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        })
      });
    }

    return fetch("".concat(_config.default.get("PUBLIC_HOST"), "/restful"), {
      method,
      credentials: "include",
      headers: _Authentication.default.injectCookie({
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      }),
      body: this.restfulEncodeBody(body)
    });
  }
  /**
   * Fetches data using POST method from new_node
   * @param {String} path
   * @param {Object} body - body of the request (will be JSON stringified)
   * @param {string} cookie
   * @return {Promise}
   */


  static POST(path) {
    var body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return fetch("".concat(_config.default.get("PUBLIC_HOST"), "/new_node/").concat(path), {
      method: "POST",
      headers: _Authentication.default.injectCookie({
        Accept: "application/json",
        "Content-Type": "application/json"
      }),
      credentials: "include",
      body: JSON.stringify(body)
    }).then(resp => {
      var contentType = resp.headers.get("content-type");

      if (/^text\/html.+/.test(contentType)) {
        return resp.text();
      }

      return resp.json();
    }).catch(_Errors.ErrorFactory.throwWrapped);
  }

  static postFormData(path, form) {
    return fetch("".concat(_config.default.get("PUBLIC_HOST"), "/new_node/").concat(path), {
      method: "POST",
      headers: _Authentication.default.injectCookie(),
      credentials: "include",
      body: form
    }).then(resp => resp.json());
  }
  /**
   * Fetches data using GET method
   * @param {String} path - path after new_node
   * @param {string} cookie
   * @return {Promise}
   */


  static GET(path) {
    return fetch("".concat(_config.default.get("PUBLIC_HOST"), "/new_node/").concat(path), {
      method: "GET",
      credentials: "include",
      headers: _Authentication.default.injectCookie()
    }).then(resp => resp.json());
  }

}

var _default = Fetcher;
exports.default = _default;