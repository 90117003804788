"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class BrokerRecommendations {
  /**
   * Gets a list of current broker recommendations for the given company
   * @param companyId - the company
   * @returns {Promise}
   */
  static getBrokerRecommendations(companyId) {
    return (0, _API.GET)("brokerRecommendations/summaries?filter=".concat(JSON.stringify({
      skip: 0,
      limit: 1,
      order: "timestamp DESC",
      where: {
        companyId: companyId
      }
    })), true);
  }

}

var _default = BrokerRecommendations;
exports.default = _default;