"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class MarketIndices {
  /**
   * Fetches the list of market indices to which this listing belongs
   * @param {String} listingId
   * @returns {Promise}
   */
  static getListingMarketIndices(listingId) {
    return (0, _API.GET)("listings/".concat(listingId, "/marketIndices"), true);
  }
  /**
   * Looks up a market index by leafname, optionally including the listings
   * @param leafname - the url leafname given to a market index
   * @param includeListings - whether or not to fetch listings at the same time
   * @returns {Promise}
   */


  static getMarketIndexByLeafname(leafname) {
    var includeListings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return (0, _API.GET)("marketIndices/?filter[where][indexLeafname]=".concat(leafname).concat(includeListings ? "&filter[include]=listings" : ""));
  }

  static getAll() {
    return (0, _API.GET)("marketIndices").then(data => {
      if (Array.isArray(data)) {
        data.forEach(elem => {
          elem.collectionId = elem.id;
        });
        return data;
      }

      return [];
    }).catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
   * Fetches the list of listings
   * @param {string} marketIndexId - id of index
   * @param {object} filter - loopback-filters
   * @returns {Promise}
   */


  static getMarketListings(marketIndexId, filter) {
    return (0, _API.GET)("marketIndices/".concat(marketIndexId, "/listings?filter=").concat(JSON.stringify(filter)));
  }
  /**
   * Fetches the list of indexes
   * @param {object} filter - loopback-filters
   * @returns {Promise}
   */


  static getMarketsByFilter(filter) {
    return (0, _API.GET)("marketIndices?filter=".concat(JSON.stringify(filter)));
  }

}

var _default = MarketIndices;
exports.default = _default;