"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Previews {
  /**
   * Retrieves preview metadata for given url
   * @param url
   * @returns {Promise}
   */
  static getPreview(url) {
    return (0, _API.GET)("previews/meta/?url=".concat(encodeURIComponent(url)), true);
  }

}

var _default = Previews;
exports.default = _default;