"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _APIHelpers = require("./APIHelpers");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class ListingCollections {}

_defineProperty(ListingCollections, "getListingCollectionById", listingCollectionId => {
  return (0, _APIHelpers.GET)("listingcollections/".concat(listingCollectionId));
});

_defineProperty(ListingCollections, "getListingCollectionQuotes", listingCollectionId => {
  return (0, _APIHelpers.GET)("listingcollections/".concat(listingCollectionId, "/quotes"));
});

_defineProperty(ListingCollections, "getListingCollectionListings", listingCollectionId => {
  return (0, _APIHelpers.GET)("listingcollections/".concat(listingCollectionId, "/listings"));
});

_defineProperty(ListingCollections, "getListingCollectionFxPairs", listingCollectionId => {
  return (0, _APIHelpers.GET)("listingcollections/".concat(listingCollectionId, "/fxpairs"));
});

_defineProperty(ListingCollections, "getListingCollectionCommodities", listingCollectionId => {
  return (0, _APIHelpers.GET)("listingcollections/".concat(listingCollectionId, "/commodities"));
});

var _default = ListingCollections;
exports.default = _default;