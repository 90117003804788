"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _config = _interopRequireDefault(require("../config"));

var _API = require("./API.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class StaticData {
  /**
  * Fetches FCA classifications data
  * @return {Promise}
  */
  static getInvestorClassifications() {
    return StaticData.GET("investorClassifications").catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
  * Fetches currency codes data
  * @return {Promise}
  */


  static getCurrencyCodes() {
    return StaticData.GET("currencyCodes").catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
  * Similar to GET from API itself, but that one has hardcoded /v2/ in it
  * so here's sort of a clone, specifically for static data endpoint
  * @return {Promise}
  */


  static GET(path) {
    return fetch("".concat(_config.default.get("API_HOST"), "/static/").concat(path), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    }).then(body => body.json()).catch(console.error);
  }

}

exports.default = StaticData;