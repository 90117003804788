"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Advisors {
  /**
   * Gets a list of advisors for a given company
   * @param companyId - the company
   * @returns {Promise}
   */
  static getAdvisors(companyId) {
    return (0, _API.GET)("advisors/?filter=".concat(JSON.stringify({
      skip: 0,
      where: {
        companyId: companyId
      }
    })), true);
  }

}

var _default = Advisors;
exports.default = _default;