"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOOPBACK = exports.DELETE = exports.PATCH = exports.UPDATE = exports.NoJwtPOST = exports.POSTQueryStringNoJwt = exports.POSTMultipart = exports.POST = exports.GET = exports.fetchWithJWT = void 0;

var _config = _interopRequireDefault(require("../config"));

var _formData = _interopRequireDefault(require("form-data"));

var _Authentication = _interopRequireDefault(require("../Authentication"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response;
}
/**
 * Helper function for fetching with JWT
 * @param {string} URL - address
 * @param {Object} [options] - fetch options.
 * @return
 */


var fetchWithJWT = (URL, options) => {
  var jwt = _config.default.get("JWT_TOKEN");

  Object.assign({
    headers: {}
  }, options);

  if (jwt) {
    Object.assign(options.headers, {
      Authorization: "Bearer ".concat(jwt.replace(/\r?\n|\r/g, ""))
    }); //Now call fetch and return Response promise.

    return fetch(URL, options).then(handleErrors).catch(console.error);
  }

  return _Authentication.default.getJWT().then(jwt => {
    Object.assign(options.headers, {
      Authorization: "Bearer ".concat(jwt.replace(/\r?\n|\r/g, ""))
    }); //Now call fetch and return Response promise.

    return fetch(URL, options).then(handleErrors).catch(console.error);
  }).catch(console.error);
};
/**
 * Fetching function with GET method for API v2 - which requiries JWT.
 * @param {string} path
 * @return {Promise} - resolves to JSON.
 */


exports.fetchWithJWT = fetchWithJWT;

var GET = function (path) {
  var usingCachedAPI = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return fetchWithJWT("".concat(_config.default.get(usingCachedAPI ? "CACHED_API_HOST" : "API_HOST"), "/v2/").concat(path), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8"
    },
    credentials: "include"
  }).then(body => body.json()).catch(console.error);
};
/**
 * Fetching function with POST method for API v2 - which requiries JWT.
 * @param {string} path
 * @param {Object} body
 * @return {Promise} - resolves to JSON.
 */


exports.GET = GET;

var POST = function (path, body) {
  return fetchWithJWT("".concat(_config.default.get("API_HOST"), "/v2/").concat(path), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify(body)
  }).then(body => body.json()).catch(console.error);
};
/**
 * POST multipart form data (e.g. file uploads) method for API v2 with JWT.
 * @param {string} path
 * @param {Object} body
 * @return {Promise} - resolves to JSON.
 */


exports.POST = POST;

var POSTMultipart = function (path, formData) {
  return fetchWithJWT("".concat(_config.default.get("API_HOST"), "/v2/").concat(path), {
    method: "POST",
    headers: {},
    body: formData
  }).then(body => body.json()).catch(console.error);
};

exports.POSTMultipart = POSTMultipart;

var POSTQueryStringNoJwt = function (path, queryString) {
  return fetch("".concat(_config.default.get("API_HOST"), "/v2/").concat(path), {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    credentials: "include",
    body: queryString
  }).then(body => body.json()).catch(console.error);
};
/**
 * Fetching function with POST method for API v2 - which does NOT require JWT.
 * @param {string} path
 * @param {Object} body
 * @return {Promise} - resolves to JSON.
 */


exports.POSTQueryStringNoJwt = POSTQueryStringNoJwt;

var NoJwtPOST = function (path, body) {
  return fetch("".concat(_config.default.get("API_HOST"), "/v2/").concat(path), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  }).then(handleErrors).then(body => body.json()).catch(console.error);
};
/**
 * PATCH method for API v2 - which requiries JWT.
 * @param {string} path
 * @param {Object} body
 * @return {Promise} - resolves to JSON.
 */


exports.NoJwtPOST = NoJwtPOST;

var UPDATE = function (path, itemId, field, value) {
  return fetchWithJWT("".concat(_config.default.get("API_HOST"), "/v2/").concat(path, "/").concat(itemId), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({
      [field]: value
    })
  }).then(body => body.json()).catch(console.error);
};
/**
 * PATCH method for API v2 - which requiries JWT.
 * @param {string} path
 * @param {string} itemId
 * @param {object} patch
 * @return {Promise} - resolves to JSON.
 */


exports.UPDATE = UPDATE;

var PATCH = function (path, itemId, patch) {
  return fetchWithJWT("".concat(_config.default.get("API_HOST"), "/v2/").concat(path, "/").concat(itemId), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify(patch)
  }).then(body => body.json()).catch(console.error);
};
/**
 * Removes a record from the API, if permitted
 * @param path
 * @param itemId
 * @constructor
 */


exports.PATCH = PATCH;

var DELETE = function (path, itemId) {
  return fetchWithJWT("".concat(_config.default.get("API_HOST"), "/v2/").concat(path, "/").concat(itemId), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  }).then(body => body.json()).catch(console.error);
};

exports.DELETE = DELETE;
var LOOPBACK = {
  /**
   * Fetches object using a single field condition
   * @param {string} service - e.g. listings, companies etc.
   * @param {string} field
   * @param {string} value
   * @return {Promise} - resolves to object
   */
  getOneByField: (service, field, value) => {
    return getObject(service + "?filter[where][".concat(field, "]=").concat(value));
  },

  /**
   * Fetches array of object from loopback.
   * @param {string} service - e.g. listings, companies etc.
   * @param {string} field
   * @param {string} value
   * @param {number} skip
   * @param {number} limit
   * @return {Promise} - resolves to array
   */
  getManyByField: function getManyByField(service, field, value) {
    var skip = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var limit = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 10;
    return getArray(service + "?filter[where][".concat(field, "]=").concat(value) + "&filter[limit]=".concat(limit) + "&filter[skip]=".concat(skip));
  },

  /**
   * Below you will find functions for fetching from loopback with custom filters
   * (limit is 25 filters, back-end enforced)
   * It is more flexible, but parsing is slower:
   * - About  1,120,000 O/S for Object > String.
   * - About 40,000,000 O/S for simple string concatination.
   * Problably won't make noticable difference, but why to use slower parsing.
   */

  /**
   * This function calls loopback with filters
   * @param {string} service
   * @param {Object} filters
   * @returns {Promise} - resolves to single object
   */
  getOne: (service, filters) => {
    return getObject(service + "?filter=".concat(JSON.stringify(filters)));
  },

  /**
   * Apply pre-stringified filters to a query
   * @param {string} service
   * @param {Object} filters
   * @returns {Promise} - resolves to an array
   */
  query: function query(service) {
    var filterString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

    if (filterString !== "" && filterString.indexOf("?") === -1 && filterString.indexOf("/") !== 0) {
      filterString = "?".concat(filterString);
    }

    return getArray("".concat(service).concat(filterString));
  },

  /**
   * This function calls loopback with filters.
   * @param {string} service
   * @param {Object} filters
   * @returns {Promise} - resolves to array
   * @example
   * return LOOPBACK.getMany("charts", {
   *   where: {
   *    listingId: listingId,
   *    timestamp: {
   *      lt: timestamp
   *    }
   *  },
   *  order: "timestamp desc",
   *  limit: limit
   * }).then(ChartsAPI.prepareData);
   **/
  getMany: (service, filters) => {
    var encodedFilter = typeof filters != "undefined" ? "?filter=".concat(JSON.stringify(filters)) : "";
    return getArray("".concat(service).concat(encodedFilter));
  }
};
/**
 * Private function
 * Makes GET call to Loopback and resolves object
 * @param {string} URL
 * @return {Promise}
 */

exports.LOOPBACK = LOOPBACK;

var getObject = URL => {
  return GET(URL).then(data => data[0] ? data[0] : {}).catch(e => {
    console.error(e);
    return {};
  });
};
/**
 * Private function
 * Makes GET call to Loopback and resolves to array
 * @param {string} URL
 * @return {Promise}
 */


var getArray = URL => {
  return GET(URL).then(data => Array.isArray(data) ? data : []).catch(e => {
    console.error(e);
    return [];
  });
};
/**
 * Encode the body to be sent by recursively stringifying
 * @param {Object} obj : body object to be sent
 */


function encodeBody(obj) {
  Object.keys(obj).map(key => {
    if (typeof obj[key] == "object") {
      obj[key] = encodeBody(obj[key]);
    }
  });
  return JSON.stringify(obj);
}