"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ErrorFactory", {
  enumerable: true,
  get: function get() {
    return _ErrorFactory.default;
  }
});
exports.NetworkError = void 0;

var _ErrorFactory = _interopRequireDefault(require("./ErrorFactory"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class ErrorBase extends Error {
  constructor(error) {
    super(error);

    _defineProperty(this, "message", void 0);

    _defineProperty(this, "stack", void 0);

    this.message = error.message;
    this.stack = error.stack;
  }

}

class NetworkError extends ErrorBase {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", void 0);
  }

}

exports.NetworkError = NetworkError;
NetworkError.prototype.name = "NetworkError";