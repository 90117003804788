"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Fetcher = _interopRequireDefault(require("./Fetcher"));

var _Authentication = _interopRequireDefault(require("../Authentication"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * This is helper class for fetching data from users endpoints
 */
class Users {
  //Cache for combined data, not to refetch

  /**
   * Returns user meta data under given key
   * @param {string} key
   * @return {Promise}
   */
  static getMeta(key) {
    return _Fetcher.default.POST("users/get_meta", {
      key: key
    });
  }
  /**
   * @param {string} key - the key to store the meta against
   * @param {string} value - the value to store against the key
   * @return {Promise}
   */


  static setMeta(key, value) {
    return _Fetcher.default.POST("users/set_meta", {
      key: key,
      value: value
    });
  }
  /**
   * Fetches data about user live pricing
   * @return {Promise}
   */


  static hasLivePricing() {
    return _Fetcher.default.POST("users/has_live_pricing", {}).catch(e => {
      console.error(e);
      return false;
    });
  }
  /**
   * Returns basic user data
   * @return {Promise}
   */


  static getBasicData() {
    return _Fetcher.default.GET("users/get_user_data").then(data => {
      if (data.error) throw data.error;
      return {
        login: true,
        id: data.ID,
        name: data.display_name,
        avatar: data.profile_image,
        user_login: data.user_login,
        email: data.user_email
      };
    }).catch(e => {
      console.error(e);
      return {
        login: false
      };
    });
  }
  /**
   * Returns basic user data by user id
   * @param {number} userId
   * @return {Promise}
   */


  static getUserLogin(userId) {
    return _Fetcher.default.GET("users/get_user_login/".concat(userId));
  }
  /**
   * Returns version of the RNS for user
   * @param {string|number} defVal - default value to use if no data, or error
   * @return
   */


  static getRNSVersion() {
    var defVal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
    return Users.getMeta("rnsVersion").then(data => {
      return data && data.length > 0 ? data[0].meta_value : defVal;
    }).catch(e => {
      console.error(e);
      return defVal;
    });
  }
  /**
   * Returns version of the Watchlist for user
   * @param {string|number} defVal - default value to use if no data, or error
   * @return
   */


  static getWatchlistVersion() {
    var defVal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 3;
    return Users.getMeta("watchlistVersion").then(data => {
      return data && data.length > 0 ? data[0].meta_value : defVal;
    }).catch(e => {
      console.error(e);
      return defVal;
    });
  }
  /**
   * Gets the status of the user being verified or not.
   * @return {Promise<boolean>}
   */


  static isVerified() {
    return _Fetcher.default.GET("users/isVerified");
  }
  /**
   * Returns whether a user is a private investor
   * @param {boolean} defVal - default value to use if no data, or error
   * @return
   */


  static isPrivateInvestor() {
    var defVal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    return Users.getMeta("private_investor").then(data => {
      return (data && data.length) > 0 ? data[0].meta_value : defVal;
    }).catch(e => {
      console.error(e);
      return defVal;
    });
  }
  /**
   * Sets user private investor status to desired value, or default which is true
   * @param {boolean} to - target value
   * @return {Promise}
   */


  static setPrivateInvestor() {
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    //deliberately not setting false values to allow for future prompts :D
    return Users.setMeta("private_investor", true);
  }
  /**
   * Fetches combined user data, or returns from cache
   * @param {boolean} force - whether to refetch data even if cache contains data.
   * @param {Object?} defParam - parameters to pass to called functions
   * @return {Promise} - resolves to object.
   */


  static getCombinedData() {
    var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var defParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new Promise(resolve => {
      //check cache before fetching, or fetch if force is true
      if (!force && Object.keys(Users.combinedData).length) {
        resolve(Users.combinedData);
      } else {
        Promise.all([Users.getBasicData(), _Authentication.default.getJWT(), Users.isPrivateInvestor(defParams.privateInvestor)]).then(results => {
          Users.combinedData = Object.assign(results[0], {
            jwt: results[1]
          }, {
            privateInvestor: results[2]
          }, {
            livePricing: false
          });
          resolve(Users.combinedData);
        }).catch(e => {
          console.error(e); //throws user out

          return {
            login: false
          };
        });
      }
    });
  }

  static manualVerification(phoneNumber) {
    return _Fetcher.default.POST("users/verifyUserByEmail", {
      phoneNumber: phoneNumber
    });
  }

  static getJobDetails(userId) {
    return _Fetcher.default.GET("users/job_details/".concat(userId));
  }

  static getFollowerCount(userId) {
    return _Fetcher.default.GET("users/followersCount/".concat(userId));
  }

  static getBPData(userId) {
    var fieldIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [1, 6];
    //api uses field_values to specify ids
    return _Fetcher.default.POST("users/get_bp_data", {
      user_id: JSON.stringify(userId),
      field_values: fieldIds
    });
  }

  static setBPData(BPData) {
    return _Fetcher.default.POST("users/set_bp_data", {
      field_values: JSON.stringify(BPData)
    });
  }

  static getUserIdFromUserLogin(userLogin) {
    return _Fetcher.default.GET("users/getBasicInformationFromLogin/".concat(userLogin));
  }
  /**
   * @param {Number} userId
   * @returns {Promise<{uri:string}>}
   */


  static getProfilePicture(userId) {
    return _Fetcher.default.POST("users/user_image", {
      user_id: JSON.stringify(userId)
    });
  }
  /**
   * Get the total number of posts submitted by user
   * @param {Number} userId
   * @returns {Promise<Number>}
   */


  static getPostCount(userId) {
    return _Fetcher.default.GET("users/postCount/".concat(userId));
  }
  /**
   * Get the list of followers for a userId
   * @param {number} userId
   * @param {number} page=0
   */


  static getFollowers(userId) {
    var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return _Fetcher.default.POST("users/followers", {
      bp_display_id: userId,
      page: page
    });
  }
  /**
   * Get the list of people following userId
   * @param {number} userId
   * @param {number} page=0
   */


  static getFollowing(userId) {
    var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return _Fetcher.default.POST("users/following", {
      bp_display_id: userId,
      page: page
    });
  }
  /**
   * Checks the user following status
   * @param {string} key
   * @return {Promise}
   */


  static isFollowing(userId) {
    return _Fetcher.default.POST("users/isFollowing", {
      leader_id: userId
    });
  }
  /**
   * Follows user
   * @param {string} userId
   * @return {Promise}
   */


  static follow(userId) {
    return _Fetcher.default.GET("users/follow/".concat(userId));
  }
  /**
   * Unfollows user
   * @param {string} userId
   * @return {Promise}
   */


  static unfollow(userId) {
    return _Fetcher.default.GET("users/unfollow/".concat(userId));
  }
  /**
   * Fetcher user basic data
   * To fetch own data, provide falsey userId
   * For anyone else it will fetch name and surname only
   */


  static getData(userId) {
    var body = {};

    if (!userId) {
      body.field_values = JSON.stringify([1, 6, 13, 7, 15, 2, 8, 4]);
    } else {
      body.user_id = userId;
    }

    return _Fetcher.default.POST("users/get_bp_data", body).then(data => {
      return {
        firstName: data[1] || "",
        lastName: data[6] || "",
        phone: data[2],
        mobile: data[8],
        city: data[15],
        dob: data[13] ? Math.floor(new Date(data[13]).valueOf() / 1000) : 0
      };
    }).catch(e => {
      console.error(e);
      return {
        firstName: "",
        lastName: ""
      };
    });
  }
  /**
   * @return {string} - url of image
   */


  static getAvatar(userId) {
    var defAvatar = "https://www.voxmarkets.co.uk/wp-content/themes/voxmarkets3/images/avatar_default.png";
    return _Fetcher.default.POST("users/user_image", {
      user_id: userId
    }).then(data => {
      if (data && data.uri && data.uri.length > 0) {
        return data.uri;
      } else {
        return defAvatar;
      }
    }).catch(e => {
      console.error(e);
      return defAvatar;
    });
  }

}

_defineProperty(Users, "combinedData", {});

var _default = Users;
exports.default = _default;