"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Sectors {
  /**
   * Fetches the complete collection industry sectors
   * @returns {Promise}
   */
  static getAllSectors() {
    return (0, _API.GET)('static/sectors', true);
  }
  /**
   * Retrieves a single sector record using its unique identifier
   * @param sectorId
   * @returns {Promise}
   */


  static getSectorById(sectorId) {
    return (0, _API.GET)("static/sectors/".concat(sectorId), true);
  }

}

var _default = Sectors;
exports.default = _default;