"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Quotes {
  /*
  Note: api-sdk does not check if the current user is permitted to use
  either of these functions. The API at the other end will return data or a 403.
   */

  /**
   * Retrieves delayed quotes for a set of listings
   * @param listingIds
   * @returns {Promise}
   */
  static getDelayedQuotes(listingIds) {
    return (0, _API.GET)("delayed/prices?filter=".concat(JSON.stringify({
      where: {
        listingId: {
          inq: listingIds
        }
      }
    })), false);
  }
  /**
   * Retrieves current quotes for a set of listings
   * @param listingIds
   * @returns {Promise}
   */


  static getLiveQuotes(listingIds) {
    return (0, _API.GET)("prices?filter=".concat(JSON.stringify({
      where: {
        listingId: {
          inq: listingIds
        }
      }
    })), false);
  }

}

var _default = Quotes;
exports.default = _default;