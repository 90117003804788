"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

var _Utils = _interopRequireDefault(require("../Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var tradingPhases = ["CLOS", "CLOG", "CPX", "PosC", "EoPC"];
var parseFields = ["mid", "bid", "ask", "last", "headline", "change", "pctChange", "high", "low", "close", "open", "volume", "marketCap", "lastTradeTime", "lastTradeDateGMT", "oneMonthAvgVolume"];

class Prices {
  /**
   * Parses price snapshot data into object, regardless whether price snapshot actually is instantiated
   * THIS FUNCTION WILL MODIFY THE FIRST ARGUMENT
   * @param {Object} ps - price snapshot
   * @param {Object?} init - initial object
   * @return {Object}
   * @example {
   *    ask:203.55,
   *    bid:203.5,
   *    change:-1.1,
   *    close:203.55,
   *    currencyCode:"GBX",
   *    headline:NaN,
   *    high:205.35,
   *    id:"5a021212442baa17844ca5a7",
   *    last:202.936,
   *    lastTradeTime:1519062290,
   *    low:202.4,
   *    marketCap:5413182365181.128,
   *    mid:203.525,
   *    open:205.1,
   *    pctChange:-0.54,
   *    tradeType:"",
   *    tradingPhase:"CLOS",
   *    volume:62631346,
   * }
   */
  static parseSnapshot(ps) {
    var init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ps;

    // If price snapshot is falsey
    if (!ps) {
      return parseFields.reduce((result, fieldName) => {
        result[fieldName] = NaN;
        return result;
      }, Object.assign({
        currencyCode: null,
        displayPrice: NaN,
        updateTime: Date.now()
      }, init));
    } //Else attempt on parsing


    ps = parseFields.reduce((result, fieldName) => {
      result[fieldName] = Prices.extractNumber(ps[fieldName]);
      return result;
    }, init);
    ps.displayPrice = Prices.getDisplayPrice(ps);
    ps.decimalPlaces = _Utils.default.getDecimalPlaces(ps.displayPrice);
    ps.updateTime = Prices.extractNumber(ps.timeOfLastUpdateGMT);
    return ps;
  }
  /**
   * Tries to find a number in a variable.
   * There are really 3 possible situations, all described by below condition
   * @param {number|string|object} variable - variable containing number value
   * @return {number}
   */


  static extractNumber(variable) {
    switch (typeof variable) {
      case "number":
        return variable;

      case "string":
        return Number(variable);
      //From v2 API

      case "object":
        return variable ? Number(variable["$numberDecimal"]) : NaN;
      //From v2 API

      default:
        return NaN;
    }
  }
  /**
   * Pulls out a correct value to display as price from snapshot.
   * Otherwise it will use "close_price" field within closed period, or headline_price
   * @param {Object} ps - price snapshot
   * Live pricing snapshot should set this flag to false
   * @return {number}
   */


  static getDisplayPrice(ps) {
    if (tradingPhases.includes(ps.tradingPhase)) {
      return ps.close;
    } else if (ps.headline || ps.headline === 0) {
      return ps.headline;
    } else {
      return ps.mid;
    }
  }
  /**
   * Returns the number of decimal places
   * @param {Number} displayPrice
   */


  static getDecimalPlaces(displayPrice) {
    return _Utils.default.getDecimalPlaces(displayPrice);
  }
  /**
   * Fetches pricing data by listing id
   * @param {string} listingId
   * @return {Promise}
   */


  static getByListing(listingId) {
    return (0, _API.GET)("prices?filter[where][listingId]=".concat(listingId)).then(data => data[0]).then(ps => Prices.parseSnapshot(ps, {
      currencyCode: ps.curCode,
      tradingPhase: ps.tradingPhase,
      tradeType: ps.tradeType,
      id: ps.id
    })).catch(e => {
      console.error(e);
      return {};
    });
  }

  static getForListings() {
    var listingIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return (0, _API.GET)("prices?filter=".concat(JSON.stringify({
      where: {
        listingId: {
          inq: listingIds
        }
      }
    }))).then(data => {
      var byListingId = {};

      if (Array.isArray(data)) {
        data.forEach(elem => {
          byListingId[elem.listingId] = Prices.parseSnapshot(elem);
        });
      }

      return byListingId;
    }).catch(e => {
      console.error(e);
      return {};
    });
  }

}

var _default = Prices;
exports.default = _default;