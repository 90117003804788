"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class RNS {
  /**
   * @param {('all'|'directors'|'dividend'|'finals'|'ims'|'trading_updates'|'holdings')} type - filter by
   * type of RNS
   * @param {('ftse100'|'ftse250'|'ftse350'|'uk100'|'uk250'|'uk350'|'aim'|'watched'|'all')} index - filter by
   * index of the issue the RNS belongs to
   * @param {number} page
   * @param {boolean} filterNavs filter NAV RNS - default value is true
   * @param {number} limit the maximum number of RNS to return
   * @return {Promise}
   */
  static getByFilters() {
    arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 50;
    console.warn("Deprecated method RNS.getByFilters");
    return Promise.resolve({});
  }
  /**
   * @param {string} tickerShort
   * @param {boolean} filterNavs - filter NAV RNS - default value is true
   * @param {number} page the page number.
   * @param {number} limit the maximum number of rns to return
   * @return {Promise}
   */


  static getByListingTicker() {
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 50;
    console.warn("Deprecated method RNS.getByListingTicker");
    return Promise.resolve({});
  }
  /**
   * @param {string} companyId
   * @param {boolean} filterNavs - filter NAV RNS - default value is true
   * @param {number} page the page number.
   * @param {number} limit the maximum number of rns to return
   * @return {Promise}
   */


  static getByCompanyId() {
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 50;
    console.warn("Deprecated method RNS.getByCompanyId");
    return Promise.resolve({});
  }
  /**
   * @param {string} storyId - the story id of the rns to fetch the content of
   * @return {Promise}
   */


  static getContent() {
    console.warn("Deprecated method RNS.getContent");
    return Promise.resolve({});
  }

  static getContentAndTicker() {
    console.warn("Deprecated method RNS.getContentAndTicker");
    return Promise.resolve({});
  }
  /**
   * Fetches one or more RNS contents by story id
   * Returns map keyed by story id
   */


  static getContentByStoryIds(ids) {
    var filter = {
      where: {
        or: ids.map(elem => ({
          storyId: elem
        }))
      },
      fields: {
        modified: true,
        storyId: true
      }
    };
    return (0, _API.GET)("rns?filter=".concat(JSON.stringify(filter))).then(data => {
      var byStoryId = {};

      if (Array.isArray(data)) {
        data.forEach(elem => {
          byStoryId[elem.storyId] = data;
        });
      }

      return byStoryId;
    }).catch(e => {
      console.error(e);
      return {};
    });
  }

}

var _default = RNS;
exports.default = _default;