"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Fetcher = _interopRequireDefault(require("./Fetcher"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class Registration {
  static isLoginAvailable(login) {
    return _Fetcher.default.GET("registration/username_available?user_name=".concat(login)).then(resp => {
      return resp.result == true;
    }).catch(e => {
      console.error(e);
      return false;
    });
  } //`/new_node/users/emailexists/${str}`


  static doesEmailExist(email) {
    return _Fetcher.default.GET("users/emailexists/".concat(email)).then(resp => {
      return resp == true;
    }).catch(e => {
      console.error(e);
      return false;
    });
  }

  static getJobs() {
    return _Fetcher.default.GET("onboarding/jobs_fca").then(result => result).catch(e => {
      console.error(e);
    });
  } // fetch(`${process.env.REACT_APP_PUBLIC_HOST}/new_node/onboarding/jobs_fca`)
  //     .then(resp => resp.json())
  //     .then(jobs => this.setState({ jobs }));

  /**
   * @param {Object} data = {
   *    email: "",
   *    password: "",
   *    firstName: "",
   *    lastName: "",
   *    classification: "",
   *    location: "",
   *    job: "",
   *    company: "",
   * }
   * @return
   */


  static register(data) {
    return _Fetcher.default.RESTFUL({
      signup_email: data.email || "",
      signup_password: data.password || "",
      first_name: data.firstName || "",
      last_name: data.lastName || "",
      "fca-classification": data.classification || "",
      city: data.location || "",
      job_pos_0: data.job || "",
      company_pos_0: data.company,
      type: "register_user",
      return_json: true
    }).then(resp => resp.text()).then(resp => {
      var result = {
        success: false
      };

      if (resp > 0) {
        result.success = true;
        result.email = data.email;
        result.password = data.password;
      } else if (resp == -1) {
        result.error = "Name can only contain alphabets.";
      } else if (resp == -2) {
        result.error = "Email already used.";
      }

      return result;
    }).catch(e => {
      console.error(e);
      return {
        error: e,
        success: false
      };
    });
  }

}

exports.default = Registration;