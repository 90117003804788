"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class PriceAlerts {
  /**
   * Fetches the current user's configured price alerts
   * @param {String} storyId
   * @returns {Promise}
   */
  static getPriceAlerts() {
    return (0, _API.GET)("priceAlerts");
  }
  /**
   * Updates an existing price alert
   * @param alertId
   * @param alert
   */


  static updatePriceAlert(alertId, alert) {
    return (0, _API.PATCH)('priceAlerts', alertId, alert);
  }
  /**
   * Creates a new price alert for a user
   * @param alert
   * @returns {Promise}
   */


  static createPriceAlert(alert) {
    return (0, _API.POST)('priceAlerts', alert);
  }
  /**
   * Deletes a price alert
   * @param alertId
   */


  static deletePriceAlert(alertId) {
    return (0, _API.DELETE)('priceAlerts', alertId);
  }

}

var _default = PriceAlerts;
exports.default = _default;