"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class MarketMovers {
  static getAll() {
    return (0, _API.GET)("delayed/marketMovers");
  }

  static getByCollectionId(collectionId) {
    return (0, _API.GET)("delayed/marketMovers?filter[where][collectionId]=".concat(collectionId)).then(data => MarketMovers.indexByField(data, "collectionId")).catch(e => {
      console.error(e);
      return {};
    });
  }
  /**
   * Get by "marketIndex", "exchange" or other
   */


  static getByType(type) {
    return (0, _API.GET)("delayed/marketMovers?filter[where][type]=".concat(type)).then(data => MarketMovers.indexByField(data, "type")).catch(e => {
      console.error(e);
      return {};
    });
  }

  static indexByField(data, field) {
    if (Array.isArray(data)) {
      try {
        var byField = {};
        data.forEach(each => {
          byField[each[field]] = each;
        });
        return byField;
      } catch (e) {
        console.error(e);
      }
    }

    return [];
  }

}

exports.default = MarketMovers;