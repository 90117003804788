"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class AdvCompanies {
  /**
   * Fetches a single company record using its unique identifier
   * @param companyId
   * @returns {Promise}
   */
  static getCompanyById(companyId) {
    return (0, _API.GET)("companies/".concat(companyId), true);
  }
  /**
   * Fetches the issues and listings for a given company
   * @param companyId
   * @returns {Promise}
   */


  static getCompanyListings(companyId) {
    return (0, _API.GET)("issues?filter=".concat(JSON.stringify({
      where: {
        issueCompanyId: companyId
      },
      include: ["listings"]
    })), true);
  }
  /**
   * Fetches an exchange record by its Factset code (e.g. LON for London Stock Exchange)
   * @param factSetCode
   * @returns {Promise}
   */


  static getExchangeByFactsetCode(factSetCode) {
    return (0, _API.GET)("exchanges?filter=".concat(JSON.stringify({
      where: {
        exchangeFactSetCode: factSetCode
      }
    })), true);
  }
  /**
   * Fetches the listings and associated issues for any current records with this ticker and exchange id
   * @param exchangeId
   * @param shortTicker
   * @returns {Promise}
   */


  static getIssueByExchangeAndTicker(exchangeId, shortTicker) {
    var filter = {
      where: {
        listingShortTicker: shortTicker.toUpperCase(),
        listingExchangeId: exchangeId,
        listingEndDate: null
      },
      include: ["issue"]
    };
    return (0, _API.GET)("listings?filter=".concat(JSON.stringify(filter)));
  }
  /**
   * Fetches the issue behind a given listing id. This can be used to determine the company id
   * @param listingId
   * @returns {Promise}
   */


  static getIssueByListingId(listingId) {
    return (0, _API.GET)("listings/".concat(listingId, "/?filter=").concat(JSON.stringify({
      include: ["issue"]
    })));
  }
  /**
   * Fetches an exchange record using its identifier
   * @param exchangeId
   * @returns {Promise}
   */


  static getExchangeById(exchangeId) {
    return (0, _API.GET)("exchanges/".concat(exchangeId), true);
  }
  /**
   * Fetches the collection of locations for a company, if any, that have the HQ flag set
   * @param companyId
   * @returns {Promise}
   */


  static getLocationsByCompanyId(companyId) {
    return (0, _API.GET)("locations?filter=".concat(JSON.stringify({
      where: {
        companyId: companyId,
        locationIsHQ: true
      }
    })), true);
  } //-----------------------

  /**
   * Fetches a company's collection of key dates
   * @param companyId
   * @returns {Promise}
   */


  static getKeyDatesByCompanyId(companyId) {
    return (0, _API.GET)("companies/".concat(companyId, "/keyDates"), true);
  } //-----------------------------------


  static getListings(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("listings?filter=".concat(JSON.stringify(combinedFilters)));
  } //-----------------------------------


  static getIssues(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("issues?filter=".concat(JSON.stringify(combinedFilters)));
  } //-----------------------------------


  static getCompanies(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("companies?filter=".concat(JSON.stringify(combinedFilters)));
  } //-----------------------------------


  static getExchanges(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("exchanges?filter=".concat(JSON.stringify(combinedFilters)));
  } //-----------------------------------


  static getLocations(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("locations?filter=".concat(JSON.stringify(combinedFilters)));
  }

}

var _default = AdvCompanies;
exports.default = _default;