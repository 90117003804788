"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var configMap = {
  PUBLIC_HOST: "https://www.voxmarkets.co.uk",
  API_HOST: "https://api.voxmarkets.co.uk",
  CACHED_API_HOST: "https://cached-api.voxmarkets.co.uk",
  JWT_TOKEN: null
};
var _default = {
  /**
   * @param {string} key - the identifier used to store a config value
   */
  get: key => {
    return configMap[key];
  },

  /**
   * @param {string} key - identifier used to store a config value
   * @param {string} value - value to be set
   */
  set: (key, value) => {
    return configMap[key] = value;
  }
};
exports.default = _default;