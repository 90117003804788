"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Prices = _interopRequireDefault(require("./v2/Prices"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Utils {
  /**
   * Returns the number of decimal places
   * @param {Number} displayPrice
   */
  static getDecimalPlaces(displayPrice) {
    if (displayPrice < 0.1) {
      return 4;
    } else if (displayPrice < 10) {
      return 3;
    } else {
      return 2;
    }
  }
  /**
   *
   * @param {number} number -
   * @param {number} decimalPlaces -
   * @return {string}
   */


  /**
   * Returns boolean true if x is 1 or true or 'true'
   * @param {variable} x - variable of any type
   * @return {boolean} true if resembles true, false otherwise
   */
  static parseBoolean(x) {
    return x == true || x == "true";
  }
  /**
   * Parses variable into number
   * @param {variable} value - variable of any type
   * @return {number}
   */


  static parseNumberOrGetZero(value) {
    var x = parseInt(value);
    return isNaN(x) ? 0 : x;
  }
  /**
   * @typedef {{favorites:{is_like:boolean, count:number},unfavorites:{is_disliked:boolean, count:number}}} ReactionsObject
   */

  /**
   * determine the status of the current post -
   * @param {ReactionsObject} likeInfo
   * @return {(none|like|dislike)} string
   */


  static getReactionStatus(likeInfo) {
    var status = "none";

    if (likeInfo.favorites.is_like == 1) {
      status = "like";
    } else if (likeInfo.unfavorites.is_disliked == 1) {
      status = "dislike";
    }

    return status;
  }

  static postTypeHash(postType) {
    switch (postType) {
      case "data_rns":
        return "RNS";

      default:
        return postType.toUpperCase();
    }
  }

  static extractGETParams(url) {
    if (typeof url != "string") {
      return {};
    }

    var rawParams = url.match(/(\w+)=([^&]*)/gi);
    var params = {};

    for (var i in rawParams) {
      var splitted = rawParams[i].split("=");
      params[splitted[0]] = splitted[1];
    }

    return params;
  }
  /**
   * Indexes array by a field
   * Returns object with key being field from each element and value be object itself.
   * Does not check for duplicate keys, just overwrites previous
   */


  static indexArrayByField(array, field) {
    if (Array.isArray(array)) {
      var dict = {};
      array.forEach(each => {
        if (each) {
          dict[each[field]] = each;
        }
      });
      return dict;
    }

    return {};
  }

}

exports.default = Utils;

_defineProperty(Utils, "formatNumber", function (number, decimalPlaces) {
  var useUnitSuffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (typeof number == "number" && !isNaN(number)) {
    try {
      var suffix = "";

      if (useUnitSuffix) {
        if (number >= 1000000000) {
          decimalPlaces = 2;
          number = number / 1000000000;
          suffix = "B";
        } else if (number >= 1000000) {
          decimalPlaces = 2;
          number = number / 1000000;
          suffix = "M";
        }
      }

      decimalPlaces = decimalPlaces != undefined ? decimalPlaces : Utils.getDecimalPlaces(number);
      var whole = number.toString().split(".")[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");

      if (decimalPlaces > 0) {
        return "".concat(whole, ".").concat(number.toFixed(decimalPlaces).split(".")[1]).concat(suffix);
      }

      return "".concat(whole).concat(suffix);
    } catch (e) {
      return "-";
    }
  }

  return "-";
});