"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class LoopbackFilter {
  constructor() {
    var _this = this;

    var initial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _defineProperty(this, "filter", {});

    _defineProperty(this, "limit", function () {
      var limit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 50;
      _this.filter.limit = limit;
      return _this;
    });

    _defineProperty(this, "skip", function () {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      _this.filter.skip = val;
      return _this;
    });

    _defineProperty(this, "order", order => {
      this.filter.order = order;
      return this;
    });

    _defineProperty(this, "where", condition => {
      if (this.filter.where) {
        this.filter.where = _objectSpread(_objectSpread({}, this.filter.where), condition);
      } else {
        this.filter.where = condition;
      }

      return this;
    });

    _defineProperty(this, "and", conditions => {
      if (this.filter.where) {
        if (this.filter.where.and) {
          this.filter.where.and.concat(conditions);
        } else {
          this.filter.where.and = conditions;
        }
      } else {
        this.filter.where = {
          and: conditions
        };
      }

      return this;
    });

    _defineProperty(this, "requireFieldStructure", structure => {
      if (typeof structure == "object" && structure) {
        var keys = Object.keys(structure);

        if (keys.length) {
          this.and(keys.reduce((accumulator, property) => {
            return accumulator.concat(Object.keys(structure[property]).map(key => ({
              [property]: {
                [key]: structure[property][key]
              }
            })));
          }, []));
        }
      }

      return this;
    });

    _defineProperty(this, "mustBe", (field, compulsory, avoid) => {
      if (typeof field == "string" && field) {
        if (Array.isArray(compulsory)) {
          var defined = compulsory.filter(each => !!each);

          if (defined.length) {
            return this.where({
              [field]: {
                inq: defined
              }
            });
          }
        }

        if (Array.isArray(avoid)) {
          var _defined = avoid.filter(each => !!each);

          if (_defined.length) {
            return this.where({
              [field]: {
                nin: _defined
              }
            });
          }
        }
      }

      return this;
    });

    _defineProperty(this, "toJSON", () => {
      return this.filter;
    });

    _defineProperty(this, "toString", () => {
      return JSON.stringify(this.filter);
    });

    if (initial instanceof LoopbackFilter) {
      this.filter = initial.filter;
    } else {
      this.filter = initial;
    }

    if (!this.filter.limit) {
      this.filter.limit = 50;
    }
  }

  fields(wanted, unwanted) {
    if (Array.isArray(wanted) && wanted.length) {
      if (!this.filter.fields) {
        this.fileds = {};
      }

      wanted.forEach(field => {
        this.filter.fields[field] = true;
      });
    } else if (Array.isArray(unwanted) && unwanted.length) {
      if (!this.filter.fields) {
        this.fileds = {};
      }

      unwanted.forEach(field => {
        this.filter.fields[field] = false;
      });
    }

    return this;
  }

  include(models) {
    var allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (Array.isArray(models) && models.length) {
      var filtered = compulsory.filter(each => {
        if (allowed.length) {
          return allowed.includes(each);
        } //null elimination


        return !!each;
      });

      if (Array.isArray(this.filter.include)) {
        this.filter.include = this.filter.include.concat(filtered);
      } else {
        this.filter.include = filtered;
      }
    }

    return this;
  }

}

exports.default = LoopbackFilter;