"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Ownership {
  /**
   * Fetches the latest ownership data for the given company
   * @param {String} companyId
   * @returns {Promise}
   */
  static getCompanyOwnership(companyId) {
    return (0, _API.GET)("ownership/?filter[where][companyId]=".concat(companyId));
  }

}

var _default = Ownership;
exports.default = _default;