"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Follows {
  /**
   * Gets the list of filtered entities
   * @param filter - loopback-filters
   * @returns {Promise}
   */
  static getFollowsByFilter(filter) {
    var combinedFilters = Object.assign({
      order: "createdAt DESC"
    }, filter);
    return (0, _API.GET)("follows?filter=".concat(JSON.stringify(combinedFilters)));
  }
  /**
   * Gets the counter
   * @param owner - the identifier of owner
   * @param followedType - the type of entity being followed
   * @returns {Promise}
   */


  static getFollowsCounterByOwner(owner, followedType) {
    return (0, _API.GET)("follows/count?where[owner]=".concat(owner, "&where[followedType]=").concat(followedType));
  }
  /**
   * Gets the counter
   * @param followedId - the identifier of the entity to follow
   * @param followedType - the type of entity being followed
   * @returns {Promise}
   */


  static getFollowsCounterByFollowedId(followedId, followedType) {
    return (0, _API.GET)("follows/count?where[followedId]=".concat(followedId, "&where[followedType]=").concat(followedType));
  }
  /**
   * Gets the list of entities being followed by the given user.
   * @param userId - identifies the user for whom follow data is required
   * @returns {Promise}
   */


  static getFollows(userId) {
    return (0, _API.GET)("follows?filter[where][owner]=".concat(userId));
  }
  /**
   * Follows a new entity
   * @param followedType - the type of entity being followed
   * @param followedId - the identifier of the entity to follow
   * @returns {Promise}
   */


  static addFollow(followedType, followedId) {
    return (0, _API.POST)("follows", {
      followedId: followedId,
      followedType: followedType
    });
  }
  /**
   * Stops following a given entity
   * @param id - the follow record identifier
   * @returns {*}
   */


  static dropFollow(id) {
    return (0, _API.DELETE)("follows", id);
  }
  /**
   * Updates the follow record, such as to update email/push subscription flags
   * @param id - the follow record to update
   * @param patch - the patch to apply in the update
   */


  static updateFollowRecord(id, patch) {
    return (0, _API.PATCH)("follows", id, patch);
  }

}

var _default = Follows;
exports.default = _default;