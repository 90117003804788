"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LOOPBACK", {
  enumerable: true,
  get: function get() {
    return _API.LOOPBACK;
  }
});
Object.defineProperty(exports, "Config", {
  enumerable: true,
  get: function get() {
    return _config.default;
  }
});
Object.defineProperty(exports, "Authentication", {
  enumerable: true,
  get: function get() {
    return _Authentication.default;
  }
});
Object.defineProperty(exports, "Utils", {
  enumerable: true,
  get: function get() {
    return _Utils.default;
  }
});
exports.Errors = exports.Types = exports.legacyAPI = exports.v3API = exports.v2API = void 0;

var _API = require("./v2/API");

var v2API = _interopRequireWildcard(require("./v2"));

exports.v2API = v2API;

var v3API = _interopRequireWildcard(require("./v3"));

exports.v3API = v3API;

var legacyAPI = _interopRequireWildcard(require("./legacy"));

exports.legacyAPI = legacyAPI;

var Types = _interopRequireWildcard(require("./types"));

exports.Types = Types;

var Errors = _interopRequireWildcard(require("./Errors"));

exports.Errors = Errors;

var _config = _interopRequireDefault(require("./config"));

var _Authentication = _interopRequireDefault(require("./Authentication.js"));

var _Utils = _interopRequireDefault(require("./Utils"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }