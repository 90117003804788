"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Trades {
  /**
   * Returns an ordered list of trade either before or after a given timestamp
   * @param listingId
   * @param direction - the direction in which to fetch trades. 1 = after the timestamp; -1 before the timestamp
   * @param timestamp
   * @param limit
   */
  static getListingTrades(listingId) {
    var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
    var timestamp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Date().getTime() / 1000;
    var limit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 100;
    var filter = {
      limit: limit,
      order: "time ".concat(direction === 1 ? "ASC" : "DESC"),
      where: {
        listingId: listingId,
        time: {
          [direction === 1 ? "gte" : "lte"]: timestamp
        }
      }
    };
    return (0, _API.GET)("trades?filter=".concat(JSON.stringify(filter)));
  }
  /**
   * Returns last trades for company with ticker
   * @param {string} ticker
   * @param {number} offset
   * @param {number} beforeTimestamp
   * @param {number} afterTimestamp
   * @return {Promise}
   */


  static getByTicker() {
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    arguments.length > 2 ? arguments[2] : undefined;
    arguments.length > 3 ? arguments[3] : undefined;
    console.warn("Deprecated method Trades.getByTicker");
    return Promise.resolve({});
  }
  /**
   * Fetches the trades for listing
   * @param {string} listingId
   * @param {number} offset
   * @param {number} beforeTimestamp
   * @param {number} afterTimestamp
   * @return {Promise}
   */


  static getByListing() {
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    arguments.length > 2 ? arguments[2] : undefined;
    arguments.length > 3 ? arguments[3] : undefined;
    console.warn("Deprecated method Trades.getByListing");
    return Promise.resolve({});
  }

  static getForListing(listingIds, beforeTimestamp, afterTimestamp) {
    var limit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 50;
    var filter = {
      where: {
        listingId: {
          inq: listingIds
        }
      },
      order: "time DESC",
      limit
    };

    if (typeof beforeTimestamp == "number") {
      filter.where.time = {
        lt: beforeTimestamp
      };
    }

    if (typeof afterTimestamp == "number") {
      filter.where.time = _objectSpread(_objectSpread({}, filter.where.time), {}, {
        gt: afterTimestamp
      });
    }

    return (0, _API.GET)("trades?filter=".concat(JSON.stringify(filter))).then(data => {
      if (Array.isArray(data)) {
        data.forEach(each => {
          each.price = Number(each.price["$numberDecimal"]);
        });
        return data;
      }

      return [];
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

  static newTrade(price, time, tradeDirection, tradeId, volume) {
    return {
      price: Number(price),
      time: time,
      tradeDirection: Number(tradeDirection),
      tradeId: tradeId,
      volume: Number(volume)
    };
  }
  /**
   * @deprecated use newTrade function with defined params.
   * THIS FUNCTION MODIFIES PARAMETER OBJECT!
   * @param {Object} trade
   * @return {Object}
   */


  static parseTrade(trade) {
    console.warn("Do not use this function - use newTrade function to create an object");

    if (!trade) {
      trade = {};
    }

    var {
      price,
      time,
      tradeDirection,
      tradeId,
      volume
    } = trade;
    return Trades.newTrade(price, time, tradeDirection, tradeId, volume);
  }
  /**
   * @deprecated use newTrade function with defined params.
   * THIS FUNCTION MODIFIES PARAMETER OBJECT!
   * @param {Object} ps
   * @return {Object}
   */


  static parseTradeLegacySocket(ps) {
    console.warn("Do not use this function - use newTrade function to create an object");
    var updateTime = Date.parse(ps.last_trade_date_time) / 1000;
    return Trades.newTrade(ps.last_trade_price, updateTime, ps.last_trade_direction, ps.last_trade_code, ps.volume);
  }

}

var _default = Trades;
exports.default = _default;