"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Prices = _interopRequireDefault(require("./Prices.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var indexes = ["ftse100", "ftse250", "ftse350", "uk100", "uk250", "uk350", "aim", "watched", "all"];

class Markets {
  /**
   * Fetches markets winers
   * @param {string} index
   * @param {number} limit
   * @return {Promise} resolves to object from example
   * @example Result:
   * {
   *   prices: {listingId: {mid, high}},
   *   companies: {companyId: {ticker}}
   * }
   */
  static getWinners() {
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
    console.warn("Deprecated method Markets.getWinners");
    return Promise.resolve({});
  }
  /**
   * Fetches markets losers
   * @param {string} index
   * @param {number} limit
   * @return {Promise} resolves to object from example
   * @example Result:
   * {
   *   prices: {listingId: {mid, high...}},
   *   companies: {companyId: {ticker}}
   * }
   */


  static getLosers() {
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
    console.warn("Deprecated method Markets.getLosers");
    return Promise.resolve({});
  }

}

var _default = Markets;
exports.default = _default;