"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Timeline {
  static paramsToString() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var expandDefault = Object.assign({
      limit: 50,
      direction: -1
    }, params);
    return Object.keys(expandDefault).reduce((acc, currVal) => {
      var param = "".concat(currVal, "=").concat(expandDefault[currVal]);
      return acc ? "".concat(acc, "&").concat(param) : param;
    }, "");
  }
  /**
   * Fetches the signed-in user's homepage timeline
   * @param timestamp - the epoch seconds timestamp from which to fetch timeline posts
   * @param limit - the maximum number of items to retrieve
   * @returns {Promise}
   */


  static getMyTimeline(params) {
    return (0, _API.GET)("chronology/me?".concat(Timeline.paramsToString(params)));
  }
  /**
   * Fetches the public timeline for a given company
   * @param companyId
   * @param timestamp - the epoch seconds timestamp from which to fetch timeline posts
   * @param limit - the maximum number of items to retrieve
   */


  static getCompanyTimeline(companyId, params) {
    return (0, _API.GET)("chronology/companies/".concat(companyId, "?").concat(Timeline.paramsToString(params)));
  }
  /**
   * Fetches the timeline for a given entity (e.g. Market Index)
   * @param entityId
   * @param timestamp - the epoch seconds timestamp from which to fetch timeline posts
   * @param limit - the maximum number of items to retrieve
   */


  static getEntityTimeline(entityId, params) {
    return (0, _API.GET)("chronology/entities/".concat(entityId, "?").concat(Timeline.paramsToString(params)));
  }
  /**
   * Fetches the coments for given object (e.g. Post, Article)
   * @param entityId
   */


  static getComments(entityId) {
    return this.getEntityTimeline(entityId, {
      postType: "usercomment",
      limit: 100
    });
  }
  /**
   * Fetches single timeline item
   * @param entityId
   */


  static getItem(entityId) {
    if (!entityId) {
      console.log("trying to fetch simple without id");
      return {};
    }

    return (0, _API.GET)("chronology/simple/".concat(entityId));
  }
  /**
   * Fetches the public timeline of a user
   * @param userId - identifies the user whose timeline is being requested
   * @param timestamp - the epoch seconds timestamp from which to fetch timeline posts
   * @param limit - the maximum number of items to retrieve
   * @returns {Promise}
   */


  static getUserTimeline(userId, params) {
    return (0, _API.GET)("chronology/users/".concat(userId, "?").concat(Timeline.paramsToString(params)));
  }
  /**
   * Creates new entry in chronology (post, comment)
   */


  static createEntry(content) {
    var targets = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var params = arguments.length > 2 ? arguments[2] : undefined;

    var body = _objectSpread({
      postContent: content
    }, params);

    if (targets.length > 0) {
      body.targets = targets;
    }

    return (0, _API.POST)("chronology/post", body);
  }

}

var _default = Timeline;
exports.default = _default;