"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Search {
  static people(displayName) {
    return (0, _API.GET)("search/people?filter=".concat(JSON.stringify({
      where: {
        display_name: {
          like: displayName,
          options: "i"
        }
      },
      limit: 50
    }))).then(data => {
      return Array.isArray(data) ? data : [];
    }).catch(e => {
      console.error(e);
      return [];
    });
  }

}

exports.default = Search;