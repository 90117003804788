"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

var _LoopbackFilter = _interopRequireDefault(require("../helpers/LoopbackFilter"));

var _Timeline = _interopRequireDefault(require("./Timeline"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ME = 0;
var ENTITY = 1;
var COMPANY = 2;

class NewsMetadata {
  /**
   * @param {number} filter - loopback-filters
   */
  static getList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "timestamp ASC"
    }, filter);
    return (0, _API.GET)("news/metadata/?filter=".concat(JSON.stringify(combinedFilters)));
  }

  static getWithFilter() {
    var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    filter = new _LoopbackFilter.default(filter).order("timestamp DESC").skip(options.skip || 0).mustBe("rnsCategory", options.categories, options.excludedCategories);
    return (0, _API.GET)("news/metadata/?filter=".concat(filter)).then(data => {
      if (Array.isArray(data)) {
        var byStoryId = {};
        data.forEach(story => {
          byStoryId[story.storyId] = story;
          byStoryId[story.storyId].date = story.timestamp;
        });
        return byStoryId;
      }

      return {};
    }).catch(e => {
      console.error(e);
      return {};
    });
  }

  static getUnfiltered() {
    var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    filter = new _LoopbackFilter.default(filter).order("timestamp DESC").skip(options.skip || 0);
    return (0, _API.GET)("news/metadata/?filter=".concat(filter)).then(data => {
      if (Array.isArray(data)) {
        var byStoryId = {};
        data.forEach(story => {
          byStoryId[story.storyId] = story;
          byStoryId[story.storyId].date = story.timestamp;
        });
        return byStoryId;
      }

      return {};
    }).catch(e => {
      console.error(e);
      return {};
    });
  }
  /**
   * Fetches the metadata for those news articles that fall between the given date range.
   * Both timestamp parameters are inclusive
   * @param {Date} startingAt - oldest date
   * @param {Date} endingAt - newest date
   * @param {Array} rnsCats - categories to require
   * @param {Array} excludedCats - categories to filter out
   * @returns {Promise}
   */


  static getWithinRange() {
    var startingAt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "0001-01-01 01:01:01.000";
    var endingAt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "9999-01-01 01:01:01.000";
    var categories = arguments.length > 2 ? arguments[2] : undefined;
    var excludedCategories = arguments.length > 3 ? arguments[3] : undefined;
    return NewsMetadata.getWithFilter({
      where: {
        [typeof startingAt == "number" ? "timestampSeconds" : "timestamp"]: {
          between: [startingAt, endingAt]
        }
      }
    }, {
      categories,
      excludedCategories
    });
  }

  static getMeta() {
    var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 253370764861;
    var categories = arguments.length > 1 ? arguments[1] : undefined;
    var excludedCategories = arguments.length > 2 ? arguments[2] : undefined;
    var direction = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -1;
    var skip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    return NewsMetadata.getWithFilter({
      where: {
        timestampSeconds: {
          [direction == 1 ? "gte" : "lte"]: timestamp
        }
      }
    }, {
      skip,
      categories,
      excludedCategories
    });
  }

  static getBeforeTimestamp() {
    var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 253370764861;
    var categories = arguments.length > 1 ? arguments[1] : undefined;
    var excludedCategories = arguments.length > 2 ? arguments[2] : undefined;
    var skip = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    return NewsMetadata.getMeta(timestamp, categories, excludedCategories, -1, skip);
  }

  static getAfterTimestamp() {
    var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var categories = arguments.length > 1 ? arguments[1] : undefined;
    var excludedCategories = arguments.length > 2 ? arguments[2] : undefined;
    var skip = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    return NewsMetadata.getMeta(timestamp, categories, excludedCategories, 1, skip);
  }
  /**
   * Retrieves metadata for a list of news articles
   * @param {Array} storyIds
   * @returns {Promise}
   */


  static getByStoryIds(storyIds) {
    if (storyIds.length === 1) {
      return NewsMetadata.getUnfiltered({
        where: {
          storyId: storyIds[0]
        }
      });
    }

    return NewsMetadata.getUnfiltered({
      where: {
        storyId: {
          inq: storyIds
        }
      }
    });
  }
  /**
   * Retrieves metadata for news articles matching search query in the title
   * @param {query} string
   * @returns {Promise}
   */


  static search(query) {
    var includeSummary = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (includeSummary) {
      return NewsMetadata.getWithFilter({
        where: {
          or: [{
            storyHeadline: {
              like: query
            }
          }, {
            summary: {
              like: query
            }
          }]
        }
      });
    }

    return NewsMetadata.getWithFilter({
      where: {
        storyHeadline: {
          like: query
        }
      }
    });
  }

  static getFromTimeline(whichTimeline, id) {
    var timestamp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Math.floor(Date.now() / 1000);
    var direction = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -1;
    var skip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    var timelinePromise;
    var params = {
      timestamp,
      direction,
      skip,
      limit: 50,
      postType: "news"
    };

    switch (whichTimeline) {
      case ME:
        timelinePromise = _Timeline.default.getMyTimeline(params);
        break;

      case ENTITY:
        timelinePromise = _Timeline.default.getEntityTimeline(id, params);
        break;

      case COMPANY:
        timelinePromise = _Timeline.default.getCompanyTimeline(id, params);
        break;

      default:
        throw new Error("Timeline news for ".concat(whichTimeline, " are not supported"));
    }

    return NewsMetadata.mapTimelineToMeta(timelinePromise, id);
  }

  static mapTimelineToMeta(timelinePromise, timelineOwnerId) {
    return timelinePromise.then(posts => {
      if (posts && posts.hasOwnProperty("timeline")) {
        var storyIds = posts.timeline.filter(post => post.hasOwnProperty("metadata") && "storyId" in post.metadata).map(post => post.metadata.storyId);
        return NewsMetadata.getByStoryIds(storyIds).then(result => {
          return Object.keys(result).reduce((stories, key) => {
            var story = _objectSpread(_objectSpread({}, result[key]), {}, {
              timelineOwnerId
            });

            return Object.assign(stories, {
              [key]: story
            });
          }, {});
        });
      } else throw "Timeline not found for entity %s", timelineOwnerId;
    });
  }

  static getForMarket(marketIndexId, timestamp, direction, skip) {
    return NewsMetadata.getFromTimeline(ENTITY, marketIndexId, timestamp, direction, skip);
  }

  static getForUser(userId, timestamp, direction, skip) {
    return NewsMetadata.getFromTimeline(ENTITY, userId, timestamp, direction, skip);
  }

  static getForCompany(props) {
    var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 253370764861;
    var direction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
    var skip = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var {
      listingIds,
      issueIds,
      companyId
    } = props;
    var orFilter = [];

    if (!companyId) {
      if (listingIds && listingIds.length > 0) {
        orFilter.push({
          "mentions.id": {
            inq: listingIds
          }
        });
      }

      if (issueIds && issueIds.length > 0) {
        orFilter.push({
          rnsPublishingIssueId: {
            inq: issueIds
          }
        });
        orFilter.push({
          publishingIssueId: {
            inq: issueIds
          }
        });
      }
    }

    return orFilter.length > 0 ? NewsMetadata.getWithFilter({
      where: {
        or: orFilter,
        timestampSeconds: {
          [direction == 1 ? "gte" : "lte"]: timestamp
        }
      }
    }, {
      skip
    }) : companyId ? NewsMetadata.getUnfiltered({
      where: {
        publishingCompanyId: companyId,
        timestampSeconds: {
          [direction == 1 ? "gte" : "lte"]: timestamp
        }
      }
    }, {
      skip
    }) : new Promise(res => res({}));
  }

  static getForCompanyBeforeTimestamp(props) {
    var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 253370764861;
    var skip = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    return getForCompany(props, timestamp, -1, skip);
  }

}

var _default = NewsMetadata;
exports.default = _default;