"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

// https://api.voxmarkets.co.uk/v2/media/assets?filter%5Bwhere%5D%5Bmentions%5D=567
class MediaAssets {
  static getByUserId(idInMentions) {
    var skip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
    return (0, _API.GET)("media/assets?filter[where][mentions]=".concat(idInMentions, "&filter[limit]=").concat(limit, "&filter[skip]=").concat(skip, "&filter[where][mediaIsPublished]=true&filter[order]=publishedAt DESC"));
  }

  static get(id) {
    return (0, _API.GET)("media/assets/".concat(id));
  }
  /**
   * @param {number} filter - loopback-filters
   */


  static getList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "publishedAt DESC"
    }, filter);
    return (0, _API.GET)("media/assets?filter=".concat(JSON.stringify(combinedFilters)));
  }

}

var _default = MediaAssets;
exports.default = _default;