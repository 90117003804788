"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class FullSearch {
  /**
   * Sends a search request to the Elasticsearch wrapper endpoint
   * @param query - search query
   * @param index - the content store index against which to run the query
   * @param from - indicates the search result index of the first result to be returned
   * @returns {Promise}
   */
  static runSearch(query) {
    var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "_all";
    var from = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    return (0, _API.GET)("fullsearch?q=".concat(encodeURIComponent(query), "&index=").concat(index, "&from=").concat(from), false);
  }

}

var _default = FullSearch;
exports.default = _default;