"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class ShortPositions {
  /**
   * Fetches a collection of short positions as expressed on a given date
   * @param {Number} recordDate as epoch seconds
   * @returns {Promise}
   */
  static getShortPositions(recordDate) {
    return (0, _API.GET)("shortPositions/?filter[where][recordDate]=".concat(recordDate));
  }
  /**
   * Fetches a collection of short positions as expressed on a given date for a given issue
   * @param {Number} recordDate as epoch seconds
   * @returns {Promise}
   */


  static getShortPositionsByIssue(recordDate, issueId) {
    return (0, _API.GET)("shortPositions/?filter[where][recordDate]=".concat(recordDate, "&filter[where][issueId]=").concat(issueId));
  }
  /**
   * Fetches a collection of short positions as expressed on a given date sorted by position date
   * with the most recent first.
   * @param {Number} recordDate as epoch seconds
   * @param {Number} limits the number of results retrieved
   * @returns {Promise}
   */


  static getRecentShortPositions(recordDate) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 25;
    return (0, _API.GET)("shortPositions/?filter[where][recordDate]=".concat(recordDate, "&filter[limit]=").concat(limit, "&filter[order]=positionDate DESC"));
  }
  /**
   * Retrieves all the short position changes starting from, and including, a given timestamp
   * @param recordDate
   * @returns {Promise}
   */


  static getShortPositionChangesSince(recordDate) {
    return (0, _API.GET)("shortPositionChanges/?filter[where][recordDate][gte]=".concat(recordDate), true);
  }
  /**
   * Retrieves the last 'limit' short position changes for a given issue, ordered by recordDate
   * @param issueId
   * @param limit
   * @returns {Promise}
   */


  static getShortPositionChangesByIssue(issueId) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 25;
    return (0, _API.GET)("shortPositionChanges/?filter[where][issueId]=".concat(issueId, "&filter[limit]=").concat(limit, "&filter[order]=recordDate DESC"), true);
  }
  /**
   * Retrieves the last 'limit' short position changes for a given company, ordered by recordDate
   * @param companyId
   * @param limit
   * @returns {Promise}
   */


  static getShortPositionChangesByCompany(companyId) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 25;
    return (0, _API.GET)("shortPositionChanges/?filter[where][companyId]=".concat(companyId, "&filter[limit]=").concat(limit, "&filter[order]=recordDate DESC"), true);
  }

}

var _default = ShortPositions;
exports.default = _default;