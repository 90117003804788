"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Industries {
  /**
   * Fetches the complete collection industries
   * @returns {Promise}
   */
  static getAllIndustries() {
    return (0, _API.GET)('static/industries', true);
  }
  /**
   * Retrieves a single industry record using its unique identifier
   * @param industryId
   * @returns {Promise}
   */


  static getIndustryById(industryId) {
    return (0, _API.GET)("static/industries/".concat(industryId), true);
  }

}

var _default = Industries;
exports.default = _default;