"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class RelatedFollows {
  /**
   * Gets a list of companies that follows of the given company also follow
   * @param companyId - the company
   * @param limit - the number of related follows to retrieve
   * @returns {Promise}
   */
  static getRelatedFollows(companyId, limit) {
    return (0, _API.GET)("statistics/relatedFollows?filter=".concat(JSON.stringify({
      skip: 0,
      limit: limit,
      order: "popularity DESC",
      where: {
        firstCompanyId: companyId
      }
    })), true);
  }

}

var _default = RelatedFollows;
exports.default = _default;