"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Watchlists {
  /**
   * Retrieves the current user's watchlists
   * @returns {Promise}
   */
  static getWatchlists() {
    return (0, _API.GET)("watchlists?filter[include]=items", false);
  }
  /**
   * Applies an update to one of the signed-in user's watchlists
   * @param watchlistId
   * @param update
   * @returns {Promise}
   */


  static updateWatchlistMetadata(watchlistId) {
    var update = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _API.PATCH)("watchlists", watchlistId, update);
  }
  /**
   * Creates a new watchlist for the signed-in user
   * @param watchlistTitle
   * @returns {Promise}
   */


  static createWatchlist(watchlistTitle) {
    return (0, _API.POST)("watchlists", {
      watchlistTitle: watchlistTitle
    });
  }
  /**
   * Attempts to delete a specific watchlist
   * @param watchlistId
   * @returns {*}
   */


  static deleteWatchlist(watchlistId) {
    return (0, _API.DELETE)("watchlists", watchlistId);
  }
  /**
   * Retrieves watchlist Items
   * @param watchlistId
   * @returns {Promise}
   */


  static getWatchlistsItems(watchlistId) {
    return (0, _API.GET)("watchlists/".concat(watchlistId, "/items"), true);
  }
  /**
   * Creates a new watchlist item for the signed-in user
   * @param watchlistId
   * @param listingId
   * @returns {Promise}
   */


  static createWatchlistsItem(watchlistId, listingId) {
    return (0, _API.POST)("watchlists/".concat(watchlistId, "/items"), {
      listingId
    });
  }
  /**
   * Attempts to delete a specific watchlist item
   * @param watchlistId
   * @param itemId
   * @returns {Promise}
   */


  static deleteWatchlistsItem(itemId) {
    return (0, _API.DELETE)("watchlistItems", itemId);
  }

}

var _default = Watchlists;
exports.default = _default;