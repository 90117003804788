"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _APIHelpers = require("./APIHelpers");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Movers {}

_defineProperty(Movers, "getMoversById", moversId => {
  return (0, _APIHelpers.GET)("movers/".concat(moversId));
});

_defineProperty(Movers, "getMoversQuotes", moversId => {
  return (0, _APIHelpers.GET)("movers/".concat(moversId, "/quotes"));
});

_defineProperty(Movers, "getMoversByCollectionIdAndKeyFieldName", function (collectionId) {
  var keyFieldName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pctChange';
  return (0, _APIHelpers.GET)("movers/findByCollectionIdAndKeyFieldName?collectionId=".concat(collectionId, "&keyFieldName=").concat(keyFieldName));
});

var _default = Movers;
exports.default = _default;