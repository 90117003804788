"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Events {
  /**
   * @param {number} contextId - specifies the context, if any, to use when looking for matching events
   */
  static getMatchingEvents() {
    var contextId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    return (0, _API.GET)("events".concat(contextId !== null ? "/" + contextId : ""));
  }

}

var _default = Events;
exports.default = _default;