"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

// https://api.voxmarkets.co.uk/v2/static/suggestedFollows
class SuggestedFollows {
  static get(id) {
    return (0, _API.GET)("static/suggestedFollows/".concat(id));
  }
  /**
   * @param {number} filter - loopback-filters
   */


  static getList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "createdBy ASC"
    }, filter);
    return (0, _API.GET)("static/suggestedFollows?filter=".concat(JSON.stringify(combinedFilters)));
  }

}

var _default = SuggestedFollows;
exports.default = _default;