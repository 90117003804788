"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _jwtDecode = _interopRequireDefault(require("jwt-decode"));

var _Fetcher = _interopRequireDefault(require("./legacy/Fetcher"));

var _config = _interopRequireDefault(require("./config"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 *  Helper function for checking if window and
 * @return {boolean}
 */
function sessionStorageExists() {
  return typeof window != "undefined" && window.sessionStorage;
}
/**
 * This allows for private scoping of the variables
 * Time is saved in miliseconds
 */


class Authentication {
  static logout() {
    return _Fetcher.default.RESTFUL({
      type: "logout"
    }, "GET").then(Authentication.invalidateTokens);
  }
  /**
   * Returns JWT
   * @return {Promise}
   */


  static getJWT() {
    return new Promise((resolve, reject) => {
      if (Authentication.hasJWTExpired()) {
        Authentication.invalidateJWT();
        return Authentication.exchangeWPCookieForJWT().then(resolve).catch(reject);
      } else {
        return resolve(Authentication.jwt);
      }
    });
  }

  static getRoles() {
    if (!Authentication.jwt) {
      return [];
    }

    var decoded = (0, _jwtDecode.default)(Authentication.jwt);

    if (decoded && decoded.hasOwnProperty('roles')) {
      return decoded.roles;
    }

    return [];
  }
  /**
   * If wp cookie is valid, it should exchange it for JWT
   * @return {Promise}
   */


  static exchangeWPCookieForJWT() {
    if (Authentication.pendingPromise) {
      console.log('getJWT pending');
      return Authentication.pendingPromise;
    }

    console.log('getJWT new request');
    Authentication.pendingPromise = new Promise((resolve, reject) => {
      //WP cookie expired, so we won't be able to get JWT
      _Fetcher.default.GET("auth/jwt").then(data => {
        Authentication.pendingPromise = null;

        if (data && typeof data == "object" && data["vox-jwt"] && typeof data["vox-jwt"] == "string") {
          Authentication.jwt = data["vox-jwt"];
          resolve(Authentication.setJWT(Authentication.jwt));
        } else {
          reject("JWT has not been received.");
        }

        console.log('getJWT resolved');
      }).catch(e => {
        Authentication.pendingPromise = null;
        console.log('getJWT rejected');
        console.error(e);
        reject("");
      });
    });
    return Authentication.pendingPromise;
  }

  static setJWT(value) {
    try {
      var decoded = (0, _jwtDecode.default)(value);
      var issued = decoded.iat * 1000; // iat from jwt-express is in seconds

      var stales = decoded.stales; // stales in millisecons
      // Calculate the time to expiration and add it to client date;
      // Simply comparing stales date to Date() on the client wouldn't work,
      // because there will be differences between client and server time

      var expiration = Date.now() + stales - issued; //check the token we're trying to set hasn't expired

      if (Authentication.hasJWTExpired(expiration)) {
        return "";
      }

      Authentication.jwt = value;
      Authentication.jwtExpiration = expiration;

      if (sessionStorageExists()) {
        window.sessionStorage.setItem("vox-jwt", Authentication.jwt);
        window.sessionStorage.setItem("vox-jwt-expires", Authentication.jwtExpiration);
      }

      return Authentication.jwt;
    } catch (e) {
      console.error(e);
      return "";
    }
  }
  /**
   * Clears JWT
   */


  static invalidateJWT() {
    if (sessionStorageExists()) {
      window.sessionStorage.removeItem("vox-jwt");
      window.sessionStorage.removeItem("vox-jwt-expires");
    }

    Authentication.jwt = "";
    Authentication.jwtExpiration = 0;
  }

  static invalidateTokens() {
    Authentication.wpCookie = "";
    Authentication.invalidateJWT();
  }

  static getJWTExpiry() {
    if (sessionStorageExists() && window.sessionStorage.getItem("vox-jwt-expires")) {
      return window.sessionStorage.getItem("vox-jwt-expires");
    } else {
      return Authentication.jwtExpiration;
    }
  }
  /**
   * @param {number?} time - check if JWT expired. Uses jwtExpiration by default.
   * @return {Boolean}
   */


  static hasJWTExpired() {
    var expiry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Authentication.getJWTExpiry();

    try {
      return expiry - Date.now() <= 0;
    } catch (e) {
      return true;
    }
  }
  /**
   * Returns true if the cookie has expired (or expires in lest than 60 seconds)
   * @return {boolean}
   */


  static hasWPCookieExpired(cookie) {
    try {
      if (typeof cookie == "string" && cookie) {
        var time = Number(cookie.split("|")[1]);

        if (!isNaN(time)) {
          return time * 1000 - Date.now() > 60000;
        }
      }
    } catch (e) {
      console.error(e);
    }

    return true;
  }
  /**
   * Sends password reset email to user with given login
   */


  static requestPasswordReset(login) {
    return _Fetcher.default.POST("auth/request_password_reset?login=".concat(login, "&json=true?"));
  }

  static getUserData() {
    return _Fetcher.default.POST("auth/get_user_data").then(response => {
      if (response.status == 200) {
        return response.json();
      }

      throw response.status;
    }).then(json => {
      return json.cookie.cookie || json.cookie;
    }).catch(e => {
      console.error(e);
      return {};
    });
  }

  static generateWPCookie(login, password) {
    var remember = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return Authentication.loginWithWPCredentials(login, password, remember);
  }

  static loginWithWPCredentials(login, password) {
    var remember = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    Authentication.invalidateTokens();
    return _Fetcher.default.POST("auth/generate_auth_cookie", {
      login,
      password,
      remember_me: remember ? "forever" : ""
    }).then(data => {
      if (data && typeof data == "object") {
        if (data.status == "error" && data.error) {
          throw data.error;
        }

        Authentication.wpCookie = data.cookie;
        Authentication.setJWT(data["vox-jwt"]);
        return data;
      }

      throw "Authentication failed.";
    });
  }

  static authenticateWithWPCookie(cookie) {
    Authentication.invalidateTokens();
    return _Fetcher.default.POST("auth", {
      cookie
    }).then(data => {
      if (data == "Unauthorized: Access is denied due to invalid credentials.") {
        throw "Authentication cookie not valid.";
      }

      if (data && typeof data == "object") {
        if (data.status == "error" && data.error) {
          throw data.error;
        }

        Authentication.wpCookie = data.cookie;
        Authentication.setJWT(data["vox-jwt"]);
        return data;
      }

      throw "Authentication failed.";
    });
  }
  /**
   * WARNING this will not work with mobile, as Cookie is restricted header field.
   */


  static injectCookie() {
    var headers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var cookie = arguments.length > 1 ? arguments[1] : undefined;

    if (cookie) {
      headers.Cookie = cookie;
    } else if (Authentication.wpCookie) {
      var encoded = encodeURI(Authentication.wpCookie); //contains md5 hashed URL of VoxMarkets

      headers.Cookie = "api_token=".concat(encoded, "; wordpress_logged_in_fa062a91457d63069a6f770d65e63a6f=").concat(encoded);
    }

    return headers;
  }

}
/**
 * Add listeners for storage
 * @return
 */


exports.default = Authentication;

_defineProperty(Authentication, "jwt", "");

_defineProperty(Authentication, "jwtExpiration", 0);

_defineProperty(Authentication, "wpCookie", void 0);

_defineProperty(Authentication, "pendingPromise", void 0);

if (sessionStorageExists()) {
  // get values from sessionStorage
  Authentication.jwt = window.sessionStorage.getItem("vox-jwt") || ""; // update the values if token got re-fetched in another tab/window

  window.addEventListener("storage", e => {
    if (e.key === "vox-jwt") {
      Authentication.jwt = e.newValue;
    } else if (e.key === "vox-jwt-expires") {}
  });
}