"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

class Following {
  static getSuggested() {
    return (0, _API.GET)("static/suggestedFollows");
  }

}

exports.default = Following;