"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _config = _interopRequireDefault(require("../config"));

var _API = require("./API.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class Upload {
  static ingest(formData) {
    return new Promise((resolve, reject) => {
      (0, _API.fetchWithJWT)("".concat(_config.default.get("API_HOST"), "/v2/upload"), {
        method: "POST",
        headers: {},
        body: formData
      }).then(body => body.json()).then(resolve).catch(reject);
    });
  }

  static image(formData) {
    return new Promise((resolve, reject) => {
      (0, _API.POSTMultipart)("upload/images", formData).then(result => {
        if (result.success) {
          resolve(result);
        } else {
          reject(result);
        }
      });
    });
  }

  static media(formData) {
    return new Promise((resolve, reject) => {
      (0, _API.POSTMultipart)("upload/media", formData).then(result => {
        if (result.success) {
          resolve(result);
        } else {
          reject(result);
        }
      });
    });
  }

}

var _default = Upload;
exports.default = _default;