"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API.js");

var _Issues = _interopRequireDefault(require("./Issues.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
* Loopback valid fields: [
*  "id",
*  "issueId",
*  "listingExchangeId",
*  "listingShortTicker",
*  "listingTicker",
*  "listingStartDate",
*  "listingEndDate",
*  "listingFactSetSymId",
*  "owner",
*  "listingIsActive",
* ]
*/
class Listings {
  /**
   * Fetches a single listing by id
   * @param {string} listingId
   * @return {Promise}
   */
  static get(listingId) {
    return _API.LOOPBACK.getOneByField("listings", "id", listingId);
  }
  /**
   * @param {number} filter - loopback-filters
   */


  static getList(filter) {
    var combinedFilters = Object.assign({
      skip: 0,
      limit: 100,
      order: "createdAt ASC"
    }, filter);
    return (0, _API.GET)("listings?filter=".concat(JSON.stringify(combinedFilters)));
  }

  static getByISIN(isin) {
    return (0, _API.GET)("issues?filter[where][isin]=".concat(isin, "&filter[include]=listings")).then(data => {
      if (Array.isArray(data) && data.length) {
        return data[0].listings;
      }

      return [];
    }).catch(e => {
      console.error(e);
      return [];
    });
  }
  /**
   * Fetches a single listing by issue id
   * @param {string} issueId
   * @return {Promise}
   */


  static getByIssueId(issueId) {
    return _API.LOOPBACK.getOneByField("listings", "issueId", issueId);
  }
  /**
   * Fetches a single listing by ticker
   * @param {string} getByTicker
   * @return {Promise}
   */


  static getByShortTicker() {
    var shortTicker = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    return (0, _API.GET)("listings/?filter={\"where\":{\"and\":[{\"listingShortTicker\":\"".concat(shortTicker.toUpperCase(), "\"},{\"listingEndDate\":null}]}}"), true).then(data => data[0] ? data[0] : {}).catch(e => {
      console.error(e);
      return {};
    });
  }
  /**
   * Fetches a single listing by full ticker
   * @param {string} ticker
   * @return {Promise}
   */


  static getByTicker() {
    var ticker = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    return _API.LOOPBACK.getOneByField("listings", "listingTicker", ticker.toUpperCase());
  }
  /**
   * Allowed inclusions:
   * [issue, marketIndices, basic, basicDerived, advanced, advancedDerived]
   */


  static getByIds() {
    var ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var include = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return (0, _API.GET)("listings?filter=".concat(JSON.stringify({
      where: {
        id: {
          inq: ids
        }
      },
      include
    }))).then(data => {
      var listings = {};
      var issues = {};
      var marketIndices = {};
      var financials = {};

      if (Array.isArray(data)) {
        data.forEach(listing => {
          if (listing) {
            listings[listing.id] = Listings.mapLoopbackFields(listing);

            if (listing.issue) {
              issues[listing.issue.id] = _Issues.default.mapLoopbackFields(listing.issue);
            }

            if (listing.marketIndices) {
              marketIndices[listing.id] = listing.marketIndices;
            }

            if (listing.basic) {
              financials.basic = listing.basic;
            }

            if (listing.basicDerived) {
              financials.basicDerived = listing.basicDerived;
            }

            if (listing.advanced) {
              financials.advanced = listing.advanced;
            }

            if (listing.advancedDerived) {
              financials.advancedDerived = listing.advancedDerived;
            }
          }
        });
      }

      listings.includes = {
        issues,
        marketIndices,
        financials
      };
      return listings;
    }).catch(e => {
      console.error(e);
      return {};
    });
  }
  /**
   * Returns new object with fields named without prefix *listing*
   */


  static mapLoopbackFields(listing) {
    var {
      issueId,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      owner
    } = listing;
    return {
      issueId,
      exchangeId: listing.listingExchangeId,
      tickerShort: listing.listingShortTicker,
      ticker: listing.listingTicker,
      startDate: listing.listingStartDate,
      endDate: listing.listingEndDate,
      factSetSymId: listing.listingFactSetSymId,
      fundamentalsId: listing.listingFundamentalsId,
      isActive: listing.listingIsActive,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      owner
    };
  }
  /**
   * Searches latest listing by short ticker.
   */


  static searchByShortTicker(ticker) {
    var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 25;

    if (typeof ticker == "string" && ticker) {
      ticker = ticker.toUpperCase();
      return (0, _API.GET)("listings/?filter={\"where\":{\"and\":[{\"listingShortTicker\":{\"like\":\"".concat(ticker, "\"}},{\"listingEndDate\":null}]},\"limit\":").concat(limit, ",\"include\":\"issue\"}")).catch(e => {
        console.error(e);
        return [];
      });
    } else {
      new Promise(resolve => resolve([]));
    }
  }

}

var _default = Listings;
exports.default = _default;