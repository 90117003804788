"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET = void 0;

var _config = _interopRequireDefault(require("../config"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function handleErrors(response) {
  if (!response.ok) throw Error(response.statusText);
  return response;
}
/**
 * Fetching function with GET method for API v2 - which requiries JWT.
 * @param {string} path
 * @param {boolean} usingCachedAPI
 * @return {Promise} - resolves to JSON.
 */


var GET = function (path) {
  var usingCachedAPI = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return fetch("".concat(_config.default.get(usingCachedAPI ? "CACHED_API_HOST" : "API_HOST"), "/v3/").concat(path), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8"
    }
  }).then(handleErrors).then(body => body.json()).catch(console.error);
};

exports.GET = GET;