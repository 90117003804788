"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MetaData = exports.default = void 0;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

class Subscription {
  constructor(packageId, metadata) {
    var isSubscribed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var id = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "packageId", void 0);

    _defineProperty(this, "metadata", void 0);

    _defineProperty(this, "isSubscribed", void 0);

    this.packageId = packageId;
    this.metadata = metadata;
    this.isSubscribed = isSubscribed;

    if (id.length > 0) {
      this.id = id;
    }
  }

}

exports.default = Subscription;

class MetaData {
  constructor(userAgent, currentUrl) {
    _defineProperty(this, "userAgent", void 0);

    _defineProperty(this, "currentUrl", void 0);

    this.userAgent = userAgent;
    this.currentUrl = currentUrl;
  }

}

exports.MetaData = MetaData;