"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _API = require("./API");

class Reports {
  /**
   * Fetches a report by name
   * @param {String} report
   * @returns {Promise}
   */
  static getReport(report) {
    return (0, _API.GET)("statistics/publicReports/?filter[where][report]=".concat(report));
  }

}

var _default = Reports;
exports.default = _default;